export const PAGE_TITLES = {
    PRICING:"Pricing",
    UOM_LIST:"UOM",
    ADD_UOM:"Add UOM",
    EDIT_UOM: "Edit Uom",
    RESOURCE_LIST:"Resources",
    ADD_RESOURCE:"Add Resource",
    EDIT_RESOURCE:"Edit Resource",
    SERVICE_LIST:"Services",
    ADD_SERVICE:"Add Service",
    CLONE_SERVICE:"Clone Service",
    OFFER_LIST:"Offers",
    ADD_OFFER:"Add Offer",
    EDIT_OFFER:"Edit Offer",
    CLONE_OFFER:"Clone Offer",
    CUSTOMER:"Customer",
    CUSTOMER_LIST:"Customers",
    TELCO_CUSTOMER:"Customer Search",
    ADD_CUSTOMER:"Add Customer",
    EDIT_CUSTOMER:"Edit Customer",
    VIEW_CUSTOMER:"View Customer",
    CUSTOMER_ORDER:"Orders",
    ORDER:"Order",
    VIEW_ORDER:"View Order",
    INVOICE:"Invoice",
    CANCEL_ORDER:"Cancel Order",
    CUSTOMER_INVOICE:"Invoices",
    CUSTOMER_SUBSCRIBE:"Subscribe",
    RESERVATION:"Reservation",
    RESERVATION_LIST:"Reservations",
    ADD_RESERVATION:"Add Reservation",
    EDIT_RESERVATION:"Edit Reservation",
    STATION:"Station",
    STATION_LIST:"Stations",
    EDIT_STATION:"Edit Station",
    PARTNER:"Partner",
    PARTNER_LIST:"Partners",
    SETTLEMENT_POLICY_LIST:"Settlement Policies",
    ADD_PARTNER:"Add Partner",
    VIEW_PARTNER:"View Partner",
    EDIT_PARTNER:"Edit Partner",
    ADD_SETTLEMENT_POLICY:"Add Settlement Policy",
    EDIT_SETTLEMET_POLICY:"Edit Settlement Policy",
    DEVICE:"Device",
    DEVICE_LIST:"Devices",
    EDIT_DEVICE:"Edit Device",
    ADD_DEVICE:"Add Device",
    PAYMENT:"Payment",
    PAYMENT_HISTORY:"Payment History",
    POLICY:"Policy",
    ADD_POLICY:"Add Policy",
    UPDATE_POLICY:"Update Policy",
    MVNO_DASHBOARD:"Dashboard",
    USER:"User",
    USER_LIST:"Users",
    OPERATOR:"Operator",
    VIEW_OPERATOR:"View Operator",
    OPERATOR_LIST:"Operators",
    CHANGE_PLAN :"Change Offer Summary"
}