import React from "react";
import { Card, CardHeader, Table } from "reactstrap";
import { formatDate } from "../../Customers/constants/DateFormat";

const UsageTable = ({ resources }) => {
  return (
    <Card className="border border-2" style={{ height: "288px" }}>
      <CardHeader className="d-flex align-items-center">
        <h5 className="fs-5 mb-0">Usage</h5>
      </CardHeader>
      <div className="table-responsive">
        <Table className="table table-borderless table-striped">
          <thead>
            <tr>
              <th className="p-3" scope="col">Name</th>
              <th className="p-3" scope="col">Value</th>
              <th className="p-3" scope="col">Units</th>
              <th className="p-3" scope="col">Time Period</th>
            </tr>
          </thead>
          <tbody>
            {resources.map((resource, idx) => (
              <tr key={idx}>
                <td className="p-3">{resource.resourceName}</td>
                <td className="p-3">{resource.remainingBalance}</td>
                <td className="p-3">-</td>
                <td>{`${formatDate(resource.validFrom)} - ${formatDate(resource.validTo)}`}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Card>
  );
};

export default UsageTable;
