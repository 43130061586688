import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import React from "react";
import { useLocation } from "react-router";
import { MaterialReactTable } from "material-react-table";
import { Button } from "reactstrap";
import { reservationColumns } from "./constants/CustomerTableColumns";
import { useDispatch } from "react-redux";
import { getAllReservation } from "../../slices/reservations/thunk";
import { getAllStation } from "../../slices/stations/thunk";
import { reservationDataFunction, stationDataFunction } from "./constants/constantFunctions";
import { hourData, loopedDuration, loopedFormatData, stationData } from "./constants/DateFormat";
import { customerIdData } from "./constants/constantFunctionsView";
import { useNavigate } from "react-router-dom";

function ViewReservation() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [stations, setStations] = useState([])
    const [reservation, setReservation] = useState([])
    const [loading, setLoading] = useState(false)
    let date = []
    let startDuration = []
    let endDuration = []
    let reserve = []
    let hours = []
    let allStationData = [];
    let stationName = []
    let stationAddress = []
    const customerId = customerIdData()

    useEffect(() => {
        dispatch(getAllReservation())
        dispatch(getAllStation(setLoading))
    }, [dispatch])

    const reservationData = useSelector((state) => state.Reservations.reservations)
    const stationsData = useSelector((state) => state.Stations.station)

    useEffect(() => {
        setStations(stationDataFunction(stationsData, allStationData));
        setReservation(reservationDataFunction(reservationData, customerId, reserve))
    }, [stationsData, reservationData])

    startDuration = loopedDuration(reservation, startDuration, "StartTime")
    endDuration = loopedDuration(reservation, endDuration, "EndTime")
    hours = hourData(reservation, hours)
    date = loopedFormatData(reservation, date)
    stationName = stationData(reservation, stations, stationName, "Name")
    stationAddress = stationData(reservation, stations, stationAddress, "Address")

    const columns = reservationColumns(date, startDuration, endDuration, hours, stationName, stationAddress)

    return (
        <React.Fragment>
            <div className="d-flex align-items-end justify-content-end">
                <Button color="secondary" className="btn-label mb-3" onClick={() => { navigate(`/create-reservation-customer/${customerId}`) }}>
                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>New Reservation</Button>
            </div>
            {reservation &&
                <MaterialReactTable
                    columns={columns}
                    //reservation is not working
                    data={stationAddress}
                    enableDensityToggle={false}
                    enableColumnOrdering
                    manualFiltering
                    enableColumnDragging
                    icons={{
                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            fontFamily: "Inter,sans-serif"
                        }
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            fontFamily: "Inter,sans-serif"
                        }
                    }}
                />
            }
        </React.Fragment>
    )
}

export default ViewReservation;