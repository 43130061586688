export const uomColoumns = () => {
    const columns = [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 150,
      },
      {
        accessorKey: 'valueType',
        header: 'Value Type',
        size: 150,
      },
    ];
  
    return columns;
  };