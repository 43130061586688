import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';

//import images
import logoSm from "../assets/images/logo-sm.png";
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";
import logoLarge from "../assets/images/K2Charge.png";
import logoSmall from "../assets/images/logo-small.png"
import LightDark from '../Components/Common/LightDark';

//import Components
import FullScreenDropdown from '../Components/Common/FullScreenDropdown';
import ProfileDropdown from '../Components/Common/ProfileDropdown';

import { changeSidebarVisibility } from '../slices/thunks';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { viewDoc } from '../slices/customer/documentview/thunk';
import { fetchDocumentsForCustomer } from '../slices/customer/documentlist/thunk';
import { Loader } from '../Components/Utils/Loader';
import { logoViewDoc } from '../slices/Logo/thunk';
import { fetchOperatorbyId } from '../slices/OperatorManagement/GetOperatorbyID/thunk';

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
    const dispatch = useDispatch();

    const selectDashboardData = createSelector(
        (state) => state.Layout.sidebarVisibilitytype,
        (sidebarVisibilitytype) => sidebarVisibilitytype
    );
    // Inside your component
    const sidebarVisibilitytype = useSelector(selectDashboardData);

    const [search, setSearch] = useState(false);
    const [profileData, setProfileData] = useState(false)
    const [loading, setLoading] = useState()
    const [editData, setEditData] = useState(null);

    const toogleSearch = () => {
        setSearch(!search);
    };
    const roleName = localStorage.getItem("roleName")

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;
        dispatch(changeSidebarVisibility("show"));

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle('open');

        //For collapse horizontal menu
        if (document.documentElement.getAttribute('data-layout') === "horizontal") {
            document.body.classList.contains("menu") ? document.body.classList.remove("menu") : document.body.classList.add("menu");
        }

        //For collapse vertical and semibox menu
        if (sidebarVisibilitytype === "show" && (document.documentElement.getAttribute('data-layout') === "vertical" || document.documentElement.getAttribute('data-layout') === "semibox")) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'sm') ? document.documentElement.setAttribute('data-sidebar-size', '') : document.documentElement.setAttribute('data-sidebar-size', 'sm');
            } else if (windowSize > 1025) {
                document.body.classList.remove('vertical-sidebar-enable');
                (document.documentElement.getAttribute('data-sidebar-size') === 'lg') ? document.documentElement.setAttribute('data-sidebar-size', 'sm') : document.documentElement.setAttribute('data-sidebar-size', 'lg');
            } else if (windowSize <= 767) {
                document.body.classList.add('vertical-sidebar-enable');
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
        }

        //Two column menu
        if (document.documentElement.getAttribute('data-layout') === "twocolumn") {
            document.body.classList.contains('twocolumn-panel') ? document.body.classList.remove('twocolumn-panel') : document.body.classList.add('twocolumn-panel');
        }
    };
    const operatorName = localStorage.getItem('operatorName') || '';
    const operatorId = localStorage.getItem('operatorId') || '';
    const operatorLogoName = `logo`
    const operatorNameFinal = operatorName === "undefined" ? "" : operatorName
    let base64ImageData = []

    if (roleName !== "Customer") {
        useEffect(() => {
            if (roleName !== "systemUser") {
                let partnerOrCustomer = "Operator"
                dispatch(logoViewDoc(setLoading, operatorId, operatorLogoName, partnerOrCustomer, setProfileData));
            }
        }, [dispatch, roleName])

    }
    else null;

    let profile = useSelector((state) => state.LogoView.logoView)
    if (roleName !== "systemUser") {
        base64ImageData = profile?.filePath
    }
    else {
        base64ImageData = []
    }

    const initialValues = [];
    const operatorOptions = [];
    const OperatorStatusOptions = [];
    if (roleName !== "Customer") {
        useEffect(() => {
            dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions, OperatorStatusOptions, setEditData));
        }, [dispatch]);
    }
    else null;
    
    const operatorData = useSelector((state) => state?.GetOperator?.GetOperatorId ?? null);
    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                {loading && <Loader />}
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex align-items-center">
                            <div className="navbar-brand-box horizontal-logo">
                                <div className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={(profile?.filePath && roleName !== "systemUser") ? base64ImageData : logoSmall} alt="" height="22" />
                                    </span>
                                    <span>
                                        <img src={profile?.filePath && roleName !== "systemUser" ? base64ImageData : logoLarge} alt="" className='logo-image-lg' />
                                    </span>
                                </div>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={profile?.filePath && roleName !== "systemUser" ? base64ImageData : logoSmall} alt="" height="22" />
                                    </span>
                                    <span>
                                        <img src={profile?.filePath && roleName !== "systemUser" ? base64ImageData : logoLarge} alt="" height="17" />
                                    </span>
                                </Link>
                            </div>
                            <div className="operator-name fw-bold fs-6 align-self-center order-2 order-md-1 mvno-text-color">
                                {operatorData?.tradingName}
                            </div>
                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none order-1 order-md-2"
                                id="topnav-hamburger-icon">
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                        </div>
                        <div className="d-flex align-items-center ">

                            {/* FullScreenDropdown */}
                            <FullScreenDropdown />
                            {/* Dark and Bright theme mode */}
                            {roleName==="Customer"? <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            />:null}
                            {/* ProfileDropdown */}
                            <ProfileDropdown />
                           
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;