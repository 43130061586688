import * as Yup from "yup"
export const validationForOffer = (state,isEdit,isClone) => {
    let validationSchema = {}
    
    return validationSchema = Yup.object({
        offerName: Yup.string().required('Kindly enter Offer Name'),
        offerCode: Yup.string().required('Kindly enter Offer Code'),
        subscriptionStartsOn: !state && !isEdit ? Yup.mixed().required('Kindly select a value') : Yup.mixed(),
        subscriptionEndsOn: !state && !isEdit ? Yup.mixed().required('Kindly select a value') : Yup.mixed(),
        offerStartsOn: Yup.mixed().required('Kindly select start date'),
        offerEndsOn: !state && Yup.mixed().required('Kindly select end date'),
        serviceName:!isEdit && Yup.object().required('Kindly select a service name'),
        grants: Yup.array().of(
            Yup.object().shape({
                resourceType: Yup.object().required("Kindly select resource name"),
                resourceValue: Yup.number().integer('It can\'t include a decimal point').required("Kindly enter resource Value"),
                validityStartsOn:!isEdit? Yup.object().required("Kindly select validity starts on"):Yup.object(),
                validityEndsOn: !isEdit? Yup.object().required("Kindly select validity ends on"):Yup.object(),
            })
        ),
        recurringGrants: Yup.array().of(
            Yup.object().shape({
                resourceType: Yup.object().required("Kindly select resource name"),
                resourceValue: Yup.number().integer('It can\'t include a decimal point').required("Kindly enter resource Value"),
                cycleAlignment:!isEdit? Yup.object().required("Kindly select cycle alignment"):Yup.object(),
                validityStartsOn:!isEdit? Yup.object().required("Kindly select validity starts on"):Yup.object(),
                validityEndsOn: !isEdit? Yup.object().required("Kindly select validity ends on"):Yup.object(),
            })
        ),
        charges: Yup.array().of(
            Yup.object().shape({
                resourceType: Yup.object().required("Kindly select resource name"),
                resourceValue: Yup.number().integer('It can\'t include a decimal point').required("Kindly enter resource Value")
            })
        ),
        recurringCharges: Yup.array().of(
            Yup.object().shape({
                resourceType: Yup.object().required("Kindly select resource name"),
                resourceValue: Yup.number().integer('It can\'t include a decimal point').required("Kindly enter resource Value")
            })
        ),
        usageRatePlan: Yup.array().of(
            Yup.object().shape({
                usageId: Yup.number().positive('A usage id can\'t start with a minus').integer('It can\'t include a decimal point').required("Kindly enter a valid ID!"),
                eventType: Yup.string().required("Kindly enter a valid event type"),
                resourceName: Yup.mixed().required("Kindly choose a resource"),
                unitOfMeasurement: Yup.mixed().required("Kindly choose an unit of measurement"),
                priority: Yup.number().positive('A priority can\'t start with a minus').integer('It can\'t include a decimal point').required("Kindly enter a valid priority"),
                // perUnitRate: Yup.number().integer('It can\'t include a decimal point').required("Kindly enter a valid per unit rate"), //validation is added down
                flatRate: Yup.number().integer('It can\'t include a decimal point').required("Kindly enter a valid flat rate"),
                pricingModel: !isEdit?Yup.mixed().required("Kindly choose an pricing model"):Yup.mixed(),
                pricingModelNew:  Yup.array().of(
                    Yup.object().shape({
                        startingUnit: Yup.mixed().required("Kindly choose an starting unit for pricing model"),
                        endingUnit: Yup.mixed().required("Kindly choose anending unit for pricing model"), 
                        pricePerUnit: Yup.mixed().required("Kindly choose an price per unit for pricing model")
                    })
                )
            })
        ),
        effectiveAt: isEdit && Yup.mixed().required('Kindly select Effective At'),
    }, [])
}

export const validationSchemaForImport = Yup.object().shape({
    csvFile: Yup.string().required('Kindly Select a csv file'),
});