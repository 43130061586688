import { MaterialReactTable } from "material-react-table";
import { invoiceListColumns } from "./constants/CustomerTableColumns";
import { MenuItem } from '@mui/material';
import { downloadDocApiCall, invoiceChangeFunctionForAll, viewDocApiCall } from "./constants/HandleChangeFunctions";
import { InvoiceRowOption } from "./InvoiceRowOptions";
import { CardBody, Row } from "reactstrap";

export default function InvoiceListTable({setProfileData, invoiceListData, dispatch, setLoading }) {
    const columns = invoiceListColumns()
    const invoiceArray = []
    let partnerOrCustomer = ""
    const invoiceDataForAll = invoiceChangeFunctionForAll(invoiceListData, invoiceArray)
    return (
        <CardBody>
            <Row className="mb-3 mt-1 table-responsive">
                <MaterialReactTable
                    icons={{
                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: '',
                            enableHiding: true
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            fontFamily: "Inter,sans-serif",
                        }
                    }}
                    renderRowActionMenuItems={({ row, closeMenu }) => (
                        <InvoiceRowOption setProfileData={setProfileData} partnerOrCustomer={partnerOrCustomer} dispatch={dispatch} setLoading={setLoading} row={row} invoiceListData={invoiceListData} closeMenu={closeMenu} />
                    )}
            enableDensityToggle={false}
                    enableRowActions columns={columns} data={invoiceDataForAll} />
            </Row>
        </CardBody>
    )
}