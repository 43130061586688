import React, { useEffect, useState } from 'react'
import { Card, CardBody, Container } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import { Row, Col } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrders } from '../../slices/orderManagement/thunk';
import { Loader } from '../Utils/Loader';
import { orderData } from './constants/OrderData';
import { OrderListColumns } from './constants/OrderManagementTableColumns';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setDeleteSuccessMessage, setSuccessMessage } from '../../slices/toastMessage/action';
import { PAGE_TITLES } from '../Common/constants';
import { createdByFunction, namedArrayFunction, taxIncludedAmountFunctions } from './constants/OrderListingFunctions';

function OrderListing() {
    
    const orderDataArray = [];
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const permissions = useSelector((state) => state.permissions.permissions);
    const orderPermission = permissions.find((resource) => resource?.resourceName === 'order');

    useEffect(() => {
        dispatch(fetchOrders(setLoading))
    }, [dispatch])

    const order = useSelector((state) => state.OrderList.orders)
    const taxIncludedValues = taxIncludedAmountFunctions(order)
    let createdBy = createdByFunction(order)
    let namesArray = namedArrayFunction(order)

    const orderList = orderData(order, orderDataArray, taxIncludedValues, namesArray, createdBy)
    const columns = OrderListColumns()
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showDeleteMessage = useSelector((state) => state.SuccessToast.showDeleteSuccessMessage)

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Order Placed Successfully. Payment link has been sent to your mail`);
            dispatch(setSuccessMessage(false))
        }
        if (showDeleteMessage) {
            toast.success(`Order Cancelled Successfully`);
            dispatch(setDeleteSuccessMessage(false))
        }
    }, [showSuccessMessage, showDeleteMessage, dispatch]);

    return (
        <React.Fragment>
            {loading && (<Loader />)}
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.CUSTOMER_ORDER} pageTitle={PAGE_TITLES.CUSTOMER_LIST} />
                    <ToastContainer position='top-center' />
                    <Row className="mt-4">
                        <Col >
                            <Card>
                                <CardBody>
                                    <Row className="mb-3 mt-1 table-responsive">
                                        {orderList ?
                                            <MaterialReactTable
                                                columns={columns}
                                                data={orderList}
                                                enableColumnOrdering
                                                enableColumnDragging
                                                enableRowActions
                                                enableDensityToggle={false}
                                                displayColumnDefOptions={{
                                                    'mrt-row-actions': {
                                                        header: '',
                                                        enableHiding: true
                                                    },
                                                }}
                                                initialState={{
                                                    columnVisibility: {
                                                        'id': false,
                                                    },
                                                }}
                                                icons={{
                                                    DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                }}
                                                muiTableHeadCellProps={{
                                                    sx: {
                                                        fontFamily: "Inter,sans-serif"
                                                    }
                                                }}
                                                muiTableBodyCellProps={{
                                                    sx: {
                                                        fontFamily: "Inter,sans-serif"
                                                    }
                                                }}
                                                renderRowActionMenuItems={({ row, closeMenu }) => 
                                                     [
                                                        orderPermission && orderPermission?.permissionList?.includes('view') ? <MenuItem key={`${row.index}.1`} onClick={() => {
                                                            navigate(`/view-order/${row.original.id}`)
                                                        }}><  i className='ri-account-circle-line me-2' /> View</MenuItem> : null,
                                                        row.original.state === "pending" && (orderPermission && orderPermission?.permissionList?.includes('delete')) ? <MenuItem key={`${row.index}.2`} onClick={()=>{navigate(`/cancel-order/${row.original.id}`)}}><  i className='ri-delete-bin-5-line me-2' /> Cancel</MenuItem> :null
                                                    ]
                                                }
                                            />
                                            : null
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default OrderListing;