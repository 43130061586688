import React, { useEffect, useState, useMemo } from 'react';
import { Container, Form, Row, Col } from 'reactstrap';
import BreadCrumb from '../Common/BreadCrumb';
import { ToastContainer } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../Utils/Loader';
import { customerIdData } from '../Customers/constants/constantFunctionsView';
import { getOrderById } from '../../slices/orderManagement/getorder/thunk';
import { fetchAllOffers } from '../../slices/offer/thunk';
import { getAllService } from '../../slices/services/thunk';
import { createOrderPayload } from './constants/OrderPayload';
import { useStateArray } from './constants/StateArray';
import TableColumns from './constants/OrderDetailColoumn';
import { PAGE_TITLES } from '../Common/constants';
import { CancelOrder } from './CancelOrder';
import { Formik } from 'formik';
import { initialCancelValues } from './constants/initialValues';
import { validationForCancelOrder } from './constants/Formdata';
import CommonModal from '../Utils/CommonModal';
import { closeModal, toggleModal } from './constants/HandleReasonChange';
import { performCancelApiRequest, performPatchCancelRequest } from './constants/CancelOrderPayloads';
import { viewOrCancelTableData } from './constants/ViewOrCancelTableData';
import { OrderDetailsInViewCancel } from './OrderDetailsInViewCancel';
import { CustomerDetailsInViewCancel } from './CustomerDetailsInViewCancel';
import { TableDataForViewCancel } from './TableDataForViewCancel';
import { CancelOrPlaceButtons } from './CancelOrPlaceButtons';

function OrderDetails() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const OrderID = state?.orderId || '';
    const { serviceName, offerName, offerCode, termValidity, validityFrom, validityTo, formData, totalChargeAmount, unitPrice, subTotal, CustomerId, CustomerName } = state || {};
    const [status, setStatus] = useState("-");
    const [orderID, setOrderID] = useState('')
    const [displayButton, setDisplayButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const currentDate = moment();
    const [showSaveButton, setShowSaveButton] = useState(false);
    const [createOrderButton, setCreateOrderButton] = useState(false);
    const [taxAmount, setTaxAmount] = useState(0)
    const [totalAmountWithTax, setTotalAmountWithTax] = useState(0)
    const [reason, setReason] = useState('')
    const [modal, setModal] = useState(false)
    const isView = location.pathname.includes('view-order')
    const isCancel = location.pathname.includes('cancel-order')
    const stateArray = useMemo(() => useStateArray(formData, serviceName, offerName, offerCode, termValidity, validityFrom, validityTo, status, totalChargeAmount, subTotal, unitPrice, CustomerId, CustomerName, orderID), [formData, serviceName, offerName, offerCode, termValidity, validityFrom, validityTo, status, totalChargeAmount, subTotal, unitPrice, CustomerId, CustomerName, orderID]);
    const { columns, viewColumns } = TableColumns(status);
    
    useEffect(() => {
        dispatch({ type: 'ADD_ORDER', payload: stateArray });
    }, [dispatch, stateArray]);

    const orderData = useSelector((state) => state.SelectOrder);
    const allOrderData = orderData?.orders || []
    const totalAmountFromOrders = allOrderData.reduce((acc, order) => acc + order.totalChargeAmount, 0);
    const authorName = localStorage.getItem("userId")

    const payload = createOrderPayload(allOrderData, CustomerId, CustomerName, authorName);
    const deviceId = customerIdData()

    useEffect(() => {
        setShowSaveButton(status === "Acknowledged");
    }, [status, allOrderData]);

    useEffect(() => {
        if (isView || isCancel) {
            dispatch(getOrderById(deviceId, setLoading))
            dispatch(fetchAllOffers(setLoading))
            dispatch(getAllService(setLoading))
        }
    }, [])

    const orderDataById = useSelector((state) => state.GetOrderById.getOrder)
    const allOfferData = useSelector((state) => state.Offer.offer)
    const serviceData = useSelector((state) => state.Services.services)
    const cancelOrderData = useSelector((state) => state.CancelOrder.cancelOrder)

    const tableData = (isView || isCancel) && viewOrCancelTableData(allOfferData, orderDataById, serviceData)
    let { subTotalValue, taxValue, totalPrice, offerDataOfOrder } = tableData
    
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Formik initialValues={initialCancelValues} validationSchema={validationForCancelOrder(reason)} onSubmit={(values) => {
                    performCancelApiRequest(orderDataById.id, values, dispatch)
                    toggleModal(setModal)
                }}>
                    {({ values, handleChange, handleBlur, errors, touched, setFieldTouched, setFieldValue, handleSubmit }) => (
                        <Form className="needs-validation" onSubmit={handleSubmit}>
                            <Container fluid>
                                <BreadCrumb title={isView ? PAGE_TITLES.VIEW_ORDER : isCancel ? PAGE_TITLES.CANCEL_ORDER : PAGE_TITLES.CUSTOMER_SUBSCRIBE} pageTitle={isView ? PAGE_TITLES.CUSTOMER_ORDER : PAGE_TITLES.CUSTOMER_LIST} />
                                <ToastContainer position='top-center' />
                                <Row>
                                    {loading && <Loader data-testid="loader" />}
                                    <Col lg={8}>
                                        <div className="listingjs-table" id="customerList">
                                            <OrderDetailsInViewCancel isCancel={isCancel} isView={isView} orderDataById={orderDataById} orderID={orderID} status={status} currentDate={currentDate} />
                                        </div>
                                    </Col>
                                    <Col lg={4} className="d-flex">
                                        <CustomerDetailsInViewCancel isCancel={isCancel} isView={isView} orderDataById={orderDataById} CustomerId={CustomerId} CustomerName={CustomerName} />
                                    </Col>
                                    {isCancel && <CancelOrder setReason={setReason} values={values} errors={errors} touched={touched} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} handleBlur={handleBlur} handleChange={handleChange} />}
                                    <Col>
                                        <TableDataForViewCancel setOrderID={setOrderID} setTotalAmountWithTax={setTotalAmountWithTax} setTaxAmount={setTaxAmount} isCancel={isCancel} isView={isView} showSaveButton={showSaveButton} OrderID={OrderID} orderID={orderID} allOrderData={allOrderData} dispatch={dispatch} setLoading={setLoading} navigate={navigate} setDisplayButton={setDisplayButton} state={(state) => setStatus(state)} setCreateOrderButton={setCreateOrderButton} CustomerId={CustomerId} stateArray={stateArray} viewColumns={viewColumns} columns={columns} offerDataOfOrder={offerDataOfOrder} />
                                    </Col>
                                    <CancelOrPlaceButtons setTotalAmountWithTax={setTotalAmountWithTax} totalAmountWithTax={totalAmountWithTax} setTaxAmount={setTaxAmount} taxAmount={taxAmount} status={status} isView={isView} isCancel={isCancel} subTotalValue={subTotalValue} totalAmountFromOrders={totalAmountFromOrders} taxValue={taxValue} totalPrice={totalPrice} displayButton={displayButton} OrderID={OrderID} orderID={orderID} createOrderButton={createOrderButton} navigate={navigate} setCreateOrderButton={setCreateOrderButton} setLoading={setLoading} createOrCancel={"Create"} setDisplayButton={setDisplayButton} state={(state) => setStatus(state)} setOrderID={setOrderID} payload={payload} dispatch={dispatch} />
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
            <CommonModal open={modal} toggle={() => closeModal(setModal, modal)} buttonText={"Yes, Cancel It"} messageForPopUp={`Are you sure you want to Cancel this Order "${orderDataById.id}"?`} modalAction={() => { performPatchCancelRequest(setLoading, cancelOrderData.id, navigate, dispatch) }} />
        </React.Fragment>
    );
}
export default OrderDetails;