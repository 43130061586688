import * as Yup from 'yup';

export const validationSchema = (state) => {
    let validationSchema = {}
    return validationSchema = Yup.object().shape({
        name: Yup.string().required('Kindly Enter the attribute name'),
        type: Yup.mixed().required('Kindly Enter the type'),
        valueType: Yup.mixed().required('Kindly Enter the value type'),
        minimumValue: state ? Yup.number().required('Kindly enter the minimum value') : Yup.number(),
        maximumValue: state ? Yup.number().required('Kindly enter the maximum value') : Yup.number()
    },[]);
}