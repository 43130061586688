import moment from "moment";
import { setValueForSubValEndsOn, setValueForSubValStartsOn } from "./displayInfinite";

export const updateOfferJson = (values, state) => {
    let validityArray = {}
    let grantsArray = []
    let chargesArray = []
    let usageRatePlanArray = []
    let rateTier = []
    let grantsMergedArray = [...values.grants, ...values.recurringGrants]
    let chargesMergedArray = [...values.charges, ...values.recurringCharges]

    const convertedStartDateTime = moment(values?.offerStartsOn).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
    validityArray.from = convertedStartDateTime
    if (!state) {
        const convertedEndDateTime = moment(values?.offerEndsOn).endOf('day').format();
        const appendedZ = moment(convertedEndDateTime).utc().set({ hour: 23, minute: 59, second: 59 }).format();
        validityArray.to = appendedZ;
    }

    grantsMergedArray.forEach((value, index) => {
        grantsArray.push({
            "amount": parseInt(value.resourceValue),
        })
    })

    chargesMergedArray.forEach((value, index) => {
        chargesArray.push({
            "amount": parseInt(value.resourceValue),
        })
    })
    values.usageRatePlan.forEach((value, index) => {
        rateTier.push({
            "perUnitRate": parseInt(value.perUnitRate),
        })
    })
    values.usageRatePlan.forEach((value, index) => {
        usageRatePlanArray.push({
            "rateTier": rateTier
        })
    })
    const jsonObj = JSON.stringify({
        effectiveAt: moment(values?.effectiveAt).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]'),
        validity: validityArray,
        ...(grantsArray.length !== 0 && {
            Grants: grantsArray,
        }),
        ...(chargesArray.length !== 0 && {
            Charges: chargesArray,
        }),
        ...(usageRatePlanArray.length !== 0 && {
            usageRatePlan: usageRatePlanArray,
        }),
    })

    return jsonObj
}

export const handleChangeForSubscriptionValidity = (initialValues, setFieldValue, startOrEnd) => (selectedValue) => {
    if (startOrEnd === "Start") {
        setValueForSubValStartsOn(initialValues, setFieldValue, selectedValue)
    }
    else {
        setValueForSubValEndsOn(initialValues, setFieldValue, selectedValue)
    }
}