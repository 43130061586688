import { FieldArray } from "formik";
import { CardHeader, CardBody, Button, Table, FormGroup, Input,UncontrolledTooltip } from "reactstrap";
import { TablesWithoutBorders } from "../../../pages/Tables/BasicTables/BasicTablesCode";
import SelectField from "../../Utils/SelectField";
import InputField from "../../Utils/InputField";
import { LabelText } from "../../Utils/Input";
import { Link } from "react-router-dom";
import { removeRowsFromGrantsAndCharges } from "./constants/checkEmptyFields";
import { grantsAndChargesHandle, handleGrantsAndChargesRecurringFrequencyAndUsageOptions, handleGrantsAndChargesResourceValueAndUsageFields, handleInfiniteForGrantsAndCharges } from "./constants/GrantsAndRecurringFunctions";

export default function ChargesRecurring({
    values,
    errors,isEdit,
    stateCharges,setChargeRecurringInfinite,resourceData,displayInfiniteForOptions,
    recurringFrequencyOptions,displayCeilingAndFloorForOptions,setChargeRecurringCeiling,setChargeRecurringFloor,
    touched,
    setTouched,
    handleChange,
    handleChargesProrateState,
    chargeResourceOptions,
    chargeRecurringInfinite,
    chargeRecurringCeiling,
    chargeRecurringFloor,
    setFieldValue,
    setFieldTouched,
    infiniteOptions,
    isEmptyorInvalidFieldForGrantsAndCharges
}) {
    return (
        <FieldArray name="recurringCharges">
            {({ push, remove }) => (
                <>
                    <CardHeader className="d-flex justify-content-center align-items-center">
                        <h4 className="card-title mb-0 flex-grow-1 me-2" >New Charges</h4>
                        {!isEdit && <Button color="secondary" className="btn-label" onClick={() => {
                            const lastIndex = values.recurringCharges.length - 1;
                            if (lastIndex === -1 || !isEmptyorInvalidFieldForGrantsAndCharges(lastIndex, values.recurringCharges)) {
                                push({recurringFrequency:recurringFrequencyOptions[0], resourceType: '', resourceValue: "0", infinite: '', ceiling: '', floor: '',type: "2", prorate:stateCharges });
                                setTouched({
                                    ...touched,
                                    recurringCharges: {
                                        ...touched.recurringCharges,
                                        [lastIndex]: {
                                            resourceType: false,
                                        },
                                    },
                                });
                            }
                        }}  > <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Charges </Button>}
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            <div className="table-responsize">
                                <div className="scrollable-view">
                                    <Table className=" table-borderless align-middle mb-0 ">
                                        <thead>
                                            <tr>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Recurring Frequency</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Resource Type<span className="text-danger"> *</span></th>
                                                <th scope="col">Resource Value</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Infinite</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Type of Resource</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Ceiling</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Floor</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Prorate</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {values.recurringCharges.length === 0 ? (<tr className="default-height"><td colSpan={9} className=" text-center">No Charges</td></tr>) :
                                                (values.recurringCharges.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="field-length">
                                                                    <SelectField
                                                                        name={`recurringCharges[${index}].recurringFrequency`}
                                                                        value={values.recurringCharges[index].recurringFrequency} isDisabled={isEdit ? true : false}
                                                                        handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(values.recurringCharges[index].recurringFrequency,`recurringCharges[${index}].recurringFrequency`,setFieldValue,setFieldTouched)}
                                                                        defaultValue={recurringFrequencyOptions[0]}
                                                                        options={recurringFrequencyOptions}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="field-length">
                                                                    <SelectField
                                                                        className={(
                                                                            touched.recurringCharges &&
                                                                            touched.recurringCharges[index] &&
                                                                            !values.recurringCharges[index].resourceType
                                                                        ) || false ? 'error-input' : '' }
                                                                        name={`recurringCharges[${index}].resourceType`} isDisabled={isEdit ? true : false}
                                                                        value={values.recurringCharges[index].resourceType}
                                                                        handleChange={grantsAndChargesHandle(`recurringCharges[${index}].resourceType`,values.recurringCharges[index],displayInfiniteForOptions, displayCeilingAndFloorForOptions, setFieldValue, setFieldTouched, index, resourceData, setChargeRecurringInfinite, setChargeRecurringCeiling, setChargeRecurringFloor)}
                                                                        options={chargeResourceOptions} />
                                                                </div>
                                                                {errors.recurringCharges &&
                                                                    errors.recurringCharges[index] &&
                                                                    touched.recurringCharges &&
                                                                    touched.recurringCharges[index] &&
                                                                    touched.recurringCharges[index].resourceType ? (
                                                                    <p className="text-danger">
                                                                        {errors.recurringCharges[index].resourceType}
                                                                    </p>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <InputField
                                                                    name={`recurringCharges[${index}].resourceValue`}
                                                                    className={'form-control field-length3'}
                                                                    placeholder="Enter Amount"
                                                                    type="number"
                                                                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                                                                    value={values.recurringCharges[index].resourceValue}
                                                                    invalid={
                                                                        errors.recurringCharges &&
                                                                            errors.recurringCharges[index] &&
                                                                            touched.recurringCharges &&
                                                                            touched.recurringCharges[index] &&
                                                                            touched.recurringCharges[index].resourceValue &&
                                                                        errors.recurringCharges[index].resourceValue ? true : false
                                                                    }
                                                                />
                                                            </td>
                                                            {chargeRecurringInfinite[index] ?
                                                                <td>
                                                                    <div className="field-length">
                                                                        <SelectField
                                                                            name={`recurringCharges[${index}].infinite`}
                                                                            value={values.recurringCharges[index].infinite} isDisabled={isEdit ? true : false}
                                                                            handleChange={handleInfiniteForGrantsAndCharges(`recurringCharges[${index}].infinite`,values.recurringCharges[index],setFieldValue)}
                                                                            options={infiniteOptions} />
                                                                    </div>
                                                                </td>
                                                                : <td />}
                                                            <td>
                                                                <InputField className={`field-length2 ${isEdit ? "label-disable-color-edit-offer":""}`} value={"Currency"} disabled={true} />
                                                            </td>
                                                            {chargeRecurringCeiling[index] || chargeRecurringCeiling[index] === 0 ?
                                                                <td>
                                                                    <InputField className={`field-length3 ${isEdit ? "label-disable-color-edit-offer":""}`} value={values.recurringCharges[index].ceiling} disabled={true} />
                                                                </td>
                                                                : <td />
                                                            }
                                                            {chargeRecurringFloor[index] || chargeRecurringFloor[index] === 0 ?
                                                                <td>
                                                                    <InputField className={`field-length3 ${isEdit ? "label-disable-color-edit-offer":""}`} value={values.recurringCharges[index].floor} disabled={true} />
                                                                </td>
                                                                : <td />
                                                            }
                                                            {values.recurringCharges[index].recurringFrequency?.label !== "Monthly" ?
                                                                <td>
                                                                    <FormGroup switch className="d-flex align-items-start switch-state">
                                                                        <div className="me-2">
                                                                            <Input
                                                                                type={"switch"}
                                                                                disabled={isEdit ? true : false}
                                                                                checked={values.recurringCharges[index].prorate}
                                                                                onChange={() => handleChargesProrateState(index,values,setFieldValue)}
                                                                            />
                                                                        </div>
                                                                        <div className="flex-column">
                                                                            <LabelText id={"UncontrolledTooltipExample"} htmlFor={"globalOffer"} className={"text-danger"} displayText={"Prorate"} check />
                                                                            <UncontrolledTooltip target={"UncontrolledTooltipExample"} >Enable rating user based on their service consumption</UncontrolledTooltip>
                                                                        </div>
                                                                    </FormGroup>
                                                                </td> : <td />
                                                            }
                                                            {!isEdit && <td>
                                                                <div className="hstack gap-3 fs-15">
                                                                <Link to="#" className="link-danger" onClick={() => {
                                                                        remove(index)
                                                                        removeRowsFromGrantsAndCharges(setChargeRecurringInfinite, index, setChargeRecurringCeiling, setChargeRecurringFloor)
                                                                    }} ><i data-testid="delete" className="ri-delete-bin-5-line"></i></Link>
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    )
                                                }))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="d-none code-view">
                            <pre className="language-markup default-height">
                                <code>
                                    <TablesWithoutBorders />
                                </code>
                            </pre>
                        </div>
                    </CardBody>
                </>
            )}
        </FieldArray>
    )
}