import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { getUserByName } from "../../slices/UserManagement/GetUserByName/thunk";
import logoLarge from '../../assets/images/users/user-dummy-img.jpg'
import { partnerUploadDocument } from "../../slices/PartnerMangement/PartnerDocumentUpload/thunk";
import { viewDoc } from "../../slices/customer/documentview/thunk";
import { documentUpdateFile } from "../../slices/customer/documentupdatefile/thunk";
import CreateOperatorProvider from "./createOperatorProvider";
import { fetchOperatorbyId } from "../../slices/OperatorManagement/GetOperatorbyID/thunk";
import { countryOptionss, OperatorOptionsFields, OperatorStatusOptions } from '../OperatorManagement/Constant/Options'
import { logoViewDoc } from '../../slices/Logo/thunk';

export default function OperatorProfile() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [update, setUpdate] = useState(false);
    const [profileData,setProfileData] = useState(false)
    let base64ImageData = [];
    const initialValues = {
        businessName: "",
        Domain: "",
        state: "",
        city: "",
        zipCode: "",
        endDate: null,
        Address: "",
        Address2: "",
        country: "",
        firstname: "",
        lastname: "",
        username: "",
        phone: "",
        email: "",
        Status: "",
        // attributeList: []
    };

    const [profileImage, setProfileImage] = useState(logoLarge);
    const [fileSelected, setFileSelected] = useState(false);
    const operatorId = localStorage.getItem('operatorId')
    const operatorOptions = [
        {
            name: "Domain",
            value: "Operator",
            options: OperatorOptionsFields
        },
        {
            name: "country",
            value: "Operator",
            options: countryOptionss
        },
        {
            name: "Status",
            value: "Operator",
            options: OperatorStatusOptions
        }
    ]
    useEffect(() => {
        if (isEdit) {
            dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions, OperatorStatusOptions));
        }
    }, [dispatch, isEdit]);

    const operatorData = useSelector((state) => state?.GetOperator?.GetOperatorId ?? null);

    useEffect(() => {
        if (operatorData ) {
            const partnerOrCustomer = 'Operator';
            dispatch(logoViewDoc(setLoading, operatorId, "logo", partnerOrCustomer, setProfileData));
        }
    }, [dispatch, operatorData, operatorId]);

    const ViewDocument = useSelector((state) => state.LogoView.logoView)
    base64ImageData = ViewDocument?.filePath;
    useEffect(() => {
        if (base64ImageData) {
            setProfileImage(base64ImageData);
        }
    }, [base64ImageData]);

    const documentJson = useCallback((file) => {
        const formData = new FormData();
        if (operatorData?.adminDetails) {
            formData.append("ID", operatorId || '');
            formData.append("file", file);
            formData.append("documentName", "logo");
            formData.append("documentType", "logo");
            formData.append('uploadedBy', "Operator" );
        } else {
            console.error('operator details not available');
        }
        return formData;
    }, [operatorData]);

    const UpdateJson = useCallback((file) => {
        const formData = new FormData();
        if (operatorData?.adminDetails) {
            formData.append("ID", operatorId || '');
            formData.append("file", file);
            formData.append("documentName","logo");
            formData.append("documentType", "logo");
            formData.append("newDocumentName", `${"logo"}`);
            formData.append('uploadedBy', 'Operator' );
        } else {
            console.error('User details not available');
        }
        return formData;
    }, [operatorData]);

    const uploadDocApiCall = useCallback((file) => {
        if (operatorData?.adminDetails) {
            const partnerOrCustomer = "Operator";
            if (base64ImageData) {
                const updateObj = UpdateJson(file);
                dispatch(documentUpdateFile(setLoading, updateObj, operatorId, operatorData?.adminDetails?.userName, partnerOrCustomer, setUpdate, setFileSelected,navigate));
            } else {
                const documentObj = documentJson(file);
                dispatch(partnerUploadDocument(documentObj, setLoading, operatorId, operatorData?.adminDetails?.userName, partnerOrCustomer, setFileSelected,navigate));
            }
        } else {
            console.error('Operator details not available for upload');
        }
    }, [dispatch, documentJson, UpdateJson, operatorId, operatorData, base64ImageData]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileSelected(true);
            const reader = new FileReader();
            reader.onload = (e) => {
                setProfileImage(e.target.result);
            };
            reader.readAsDataURL(file);
            uploadDocApiCall(file);
        }
    };

    const toggleEdit = () => {
        setIsEdit(prevState => !prevState);
    };

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={"Business Profile"} />
                    <Row>
                        <Col xxl={3}>
                            <Card className="">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                                            <img
                                                src={profileImage}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                    onChange={handleImageUpload}
                                                    accept="image/*"
                                                />
                                                <Label
                                                    htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs"
                                                >
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <h5 className="fs-16 mb-1">{operatorData?.adminDetails?.profile?.firstName} {operatorData?.adminDetails?.profile?.lastName}</h5>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card >
                                <CardBody className="mb-2">
                                    <div className="d-flex align-items-center mb-5">
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0">Complete Your Profile</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link to="#" className="badge bg-light text-secondary fs-12" onClick={toggleEdit}>
                                                <i className="ri-edit-box-line align-bottom me-1"></i>
                                                {isEdit ? "Cancel" : "Edit"}
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="progress animated-progress custom-progress progress-label">
                                        <div className="progress-bar bg-primary" role="progressbar" style={{ width: base64ImageData ? '100%' : '80%' }}
                                            aria-valuenow={base64ImageData ? '100' : '80'} aria-valuemin="0" aria-valuemax="100">
                                            <div className="label">{base64ImageData ? '100%' : '80%'}</div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={9}>
                            <div >
                                <CreateOperatorProvider isEdit={isEdit} initialValues={initialValues}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {loading && <Loader />}
            </div>
        </React.Fragment>
    );
}