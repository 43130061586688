import { Box } from '@mui/material';
import { getStatusLabel } from '../../Utils/DecideStatus';
import { getCellStyle } from '../../Utils/DecideColor';

const TableColumns = (status) => {
    const columns = [
        { accessorKey: 'subscriptionID', header: 'Subscription ID' },
        { accessorKey: 'serviceName', header: 'Service' },
        { accessorKey: 'offerName', header: 'Offer' },
        {
            accessorFn: (row) => `${row.subscriptionStartsOn} -${row.subscriptionEndsOn}`,
            header: 'Duration'
        },
        {
            accessorFn: () => `${status}`, header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "CreateOrder"),
                } : {})} >{getStatusLabel(status)}</Box>
            )
        },
        { accessorKey: 'unitPrice', header: 'Unit Price' },
        { accessorKey: 'subTotal', header: 'Subtotal' },
    ];

    const viewColumns = [
        { accessorKey: 'subscriptionID', header: 'Subscription ID' },
        { accessorKey: 'serviceName', header: 'Service' },
        { accessorKey: 'offerName', header: 'Offer' },
        { accessorKey: 'duration', header: 'Duration' },
        {
            accessorKey: 'status', header: 'Status',
            Cell: ({ cell }) => (
                <Box sx={(theme) => (cell.getValue() ? {
                    ...getCellStyle(cell.getValue(), "ViewOrder"),
                } : {})} >{getStatusLabel(cell.getValue())}</Box>
            )
        },
        { accessorKey: 'unitPrice', header: 'Unit Price' },
        { accessorKey: 'subTotal', header: 'Subtotal' },
    ];

    return { columns, viewColumns };
};

export default TableColumns;
