import { MenuItem } from '@mui/material';
import { storeRow, setDefault } from '../../Utils/SetDefaultRowData';
import { cancelOffer } from './constants/GrantsAndRecurringFunctions';
export const OfferRowOptions = ({ setMessageForPopUp, toggleModal, row, closeMenu, setIndex, index, setActiveTab, dispatch, setLoading, offerPermission, navigate }) => {
    return (
        [
            offerPermission && offerPermission.permissionList.includes('view')
                ? <MenuItem className='row-options' data-testid="view" key="view" onClick={() => { storeRow(row, setIndex, index), row.toggleExpanded(), setDefault(row.index, setActiveTab), closeMenu() }}>
                    <i className='ri  ri-eye-line me-2' /> View </MenuItem> : null,
            offerPermission && offerPermission.permissionList.includes('update') && row.original.globalScope !== true && row?.original?.validity?.to !== "0001-01-01T00:00:00Z"
                ? <MenuItem className='row-options' data-testid="update" key="update" onClick={() => { navigate(`/update-offer/${row.original.offerCode}`) }} >
                    <i className='bx bxs-edit me-2' /> Edit </MenuItem> : null,
            offerPermission && offerPermission.permissionList.includes('update') 
                ? <MenuItem className='row-options' data-testid="clone" key="clone" onClick={() => { navigate(`/clone-offer/${row.original.offerCode}`) }} >
                    <i className='ri ri-file-copy-2-line me-2' /> Clone </MenuItem> : null,
            offerPermission && offerPermission.permissionList.includes('delete')
                ? <MenuItem className='row-options' key="delete" onClick={() => { setMessageForPopUp(`Are you sure you want to Delete this Offer "${row.original.offerCode}"?`), toggleModal(row,"single"), closeMenu() }}  >
                    <i className='ri  ri-delete-bin-5-line me-2' /> Delete </MenuItem> : null,
        ]
    )
}