import { fetchResourceStart, fetchResourceSuccess, fetchResourceFailure } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../interceptor/interceptor";
import { RESOURCE_LISTING_API } from "../constants/constants";

export const getAllResources = (setLoading) => async (dispatch) => {
    dispatch(fetchResourceStart());
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${RESOURCE_LISTING_API}`, 'GET')
        setLoading(false)
        dispatch(fetchResourceSuccess(response.reverse()));
    } catch (error) {
        dispatch(fetchResourceFailure(error.message));
    }
};

