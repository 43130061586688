import { fetchGetServiceSuccess, fetchGetServiceFailure } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { GET_SERVICE } from '../../constants/constants';
import moment from "moment";

export const getServiceByCode = (setLoading, serviceCode, initialValues, serviceOptions, setState) => async (dispatch) => {
    setLoading(true);
    try {
        const response = await determineBaseUrlAndPerformApiCall(`${GET_SERVICE}/${serviceCode}`, 'GET');
        setLoading(false);
    
        initialValues.startsOn = moment(response?.Validity?.from);
        initialValues.endson = moment(response?.Validity?.to);
        let serviceArray;
        if (response?.isBundle) {
            setState(response?.isBundle);
            serviceArray = response?.bundledServices?.map(value => ({
                label: value,
                value: value
            }));
        } else {
            setState(false);
        }
        initialValues.services = serviceArray;
        initialValues.attributeList = [];
      
        if (response?.provisioningAttributes) {
            response.provisioningAttributes.forEach((resp) => {
                initialValues.attributeList.push({
                    "type": { "label": "Provisional Attributes", "value": "Provisional Attributes" },
                    "name": { "label": resp?.name, "value": resp?.name },
                    "value": resp?.default
                });
            });
        }
        if (response?.usageAttributes) {
            response.usageAttributes.forEach((resp) => {
                let usageArray = response.usageAttributes.map(value => ({
                    label: value,
                    value: value
                }));
                initialValues.attributeList.push({
                    "type": { "label": "Usage Attributes", "value": "Usage Attributes" },
                    "name": usageArray
                });
            });
        }
        if (serviceOptions) {
            const configuration = serviceOptions.find(attr => attr.name === "eventConfigurations")?.options;

            if (configuration) {
                const valueMap = new Map(configuration.map(option => [option.value, option]));
                const eventToValueMap = new Map(
                    configuration.map(option => [`events.${option.value.replace(/ /g, '')}`, option.value])
                );

                const transformEvent = (event) => {
                    const value = eventToValueMap.get(event);
                    return value || null;
                };

                const result = response?.eventConfigurations
                    .map(transformEvent)
                    .filter(eventValue => eventValue && valueMap.has(eventValue))
                    .map(eventValue => valueMap.get(eventValue));

                initialValues.eventConfigurations = result;
            }
        } else {
            initialValues.eventConfigurations = [];
        }
        initialValues.description = response?.description || "";
        dispatch(fetchGetServiceSuccess(response));
    } catch (errorResp) {
        setLoading(false);
        dispatch(fetchGetServiceFailure(errorResp.message));
    }
};
