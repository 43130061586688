export const countryOptionss = (countryData, isCity) => {
    const eventOptions = countryData?.map((item) => {
        if (isCity) {
            return {
                label: item,
                value: item,
            };
        } else {
            return {
                label: item?.name,
                value: item?.name,
            };
        }
    }) || [];

    return eventOptions;
};

export const OperatorOptionsFields = [
    { value : 'telcoAdmin', label:'Telco'},
    { value : "operatorAdmin", label : 'EV'},
    { value : "genericAdmin", label : 'Generic'},
    { value : "ISPOperatorAdmin", label : 'ISP'}
]

export const OperatorStatusOptions = [
    { value: 'validated', label: 'Active' },
    { value: 'initialized', label: 'Inactive' },
    { value: 'closed', label: 'Cancel' }
]