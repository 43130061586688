import React, { useState, useEffect } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Label,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import { LabelText } from "../../Utils/Input";

import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerSummary } from "../../../slices/customer/simulate/thunk";
import PieChart from "../../Customers/constants/PieChart";
import { formatDate } from "../../Customers/constants/DateFormat";
import UsageTable from "./UsageTable";

const CustomerGrantBucket = () => {
  const [loading, setLoading] = useState(false);
  const [openAccordion, setOpenAccordion] = useState("2");
  const [baseOfferName, setBaseOfferName] = useState("");
  const [addOnOfferName, setAddOnOfferName] = useState("");
  const dispatch = useDispatch();
  const customerId = localStorage.getItem('customerId');

  useEffect(() => {
    const simulateOrPreview = "View";
    dispatch(fetchCustomerSummary(customerId, setLoading, simulateOrPreview));
  }, [dispatch, customerId]);

  const fetchCustomerSummaryData = useSelector(
    (state) => state.Simulate.simulate
  );

  const [baseActiveIndex, setBaseActiveIndex] = useState(0);
  const [addOnActiveIndex, setAddOnActiveIndex] = useState(0);
  const addOns = fetchCustomerSummaryData?.resources?.filter(item => item.isAddOn === true);
  const nonAddOns = fetchCustomerSummaryData?.resources?.filter(item => item.isAddOn === false);
  const IsCounterAddon = fetchCustomerSummaryData?.resources?.filter(item => item.isCounter === false && item.isAddOn === true);
  const IsCounterNonAddon = fetchCustomerSummaryData?.resources?.filter(item => item.isCounter === false && item.isAddOn === false);

  useEffect(() => {
    if (IsCounterNonAddon?.length > 0) {
      setBaseOfferName(IsCounterNonAddon[baseActiveIndex]?.offerName || "");
    }
  }, [baseActiveIndex, IsCounterNonAddon]);

  useEffect(() => {
    if (IsCounterAddon?.length > 0) {
      setAddOnOfferName(IsCounterAddon[addOnActiveIndex]?.offerName || "");
    }
  }, [addOnActiveIndex, IsCounterAddon]);

  const toggleAccordion = (id) => {
    setOpenAccordion(openAccordion === id ? "" : id);
  };

  const handleNext = (resources, activeIndex, setActiveIndex, setOfferName) => {
    const nextIndex =
      activeIndex === resources?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
    setOfferName(resources[nextIndex]?.offerName || "");
  };

  const handlePrev = (resources, activeIndex, setActiveIndex, setOfferName) => {
    const prevIndex =
      activeIndex === 0 ? resources?.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
    setOfferName(resources[prevIndex]?.offerName || "");
  };

  const renderCarousel = (resources, activeIndex, setActiveIndex, setOfferName) => {
    if (!resources?.length) return null;

    const slides = resources.map((resource, idx) => (
      <CarouselItem key={`${resource.resourceName}-${idx}`}>
        <Card className="border border-2 customer-view-screen">
          <CardHeader className="d-flex align-items-center customer-view-screen">
            <Label className="fs-5 mt-2">Grant Bucket</Label>
          </CardHeader>
          <CardBody className="px-5">
            <div className="d-flex flex-column align-items-center flex-md-row">
              <PieChart
                remainingBalance={resource.remainingBalance}
                originalBalance={resource.originalBalance}
                width={"100%"}
                height={200}
              />
              <div>
                <p className="fw-bolder fs-5">{resource.resourceName}</p>
                <p>
                  <b>{resource.remainingBalance}</b> left of{" "}
                  <b>{resource.originalBalance}</b>
                </p>
                <p className="d-flex justify-content-start">
                  <LabelText
                    displayText={`${formatDate(resource.validFrom)} - ${formatDate(
                      resource.validTo
                    )}`}
                  />
                </p>
              </div>
            </div>
          </CardBody>
        </Card>
      </CarouselItem>
    ));

    return (
      <Carousel
        activeIndex={activeIndex}
        next={() => handleNext(resources, activeIndex, setActiveIndex, setOfferName)}
        previous={() => handlePrev(resources, activeIndex, setActiveIndex, setOfferName)}
        interval={false}
      >
        <CarouselIndicators
          items={resources}
          activeIndex={activeIndex}
          onClickHandler={(newIndex) => {
            setActiveIndex(newIndex);
            setOfferName(resources[newIndex]?.offerName || "");
          }}
        />
        {slides}
        {resources?.length > 1 && (
          <>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={() => handlePrev(resources, activeIndex, setActiveIndex, setOfferName)}
              className={activeIndex === 0 ? "d-none" : ""}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={() => handleNext(resources, activeIndex, setActiveIndex, setOfferName)}
              className={activeIndex === resources?.length - 1 ? "d-none" : ""}
            />
          </>
        )}
      </Carousel>
    );
  };
  return (
    <Row lg={12}>
      <Col lg={12}>
        <Accordion open={openAccordion} toggle={toggleAccordion}>
          <AccordionItem>
            <AccordionHeader onClick={() => toggleAccordion('1')}>
              <h5 className=' mb-0'>Subscriptions</h5>
            </AccordionHeader>
            <AccordionBody accordionId='1'>
              <div className="d-flex justify-content-center align-items-center">
                <div><h5>No Records</h5></div>
              </div>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader onClick={() => toggleAccordion('2')}>
              <h5 className=' mb-0'>Base Offer</h5>
            </AccordionHeader>
            <AccordionBody accordionId="2">
              {IsCounterNonAddon?.length > 0 ? (
                <CardBody className="card-body">
                  <div className="text-start mb-3">
                    <h5>{baseOfferName}</h5>
                  </div>
                  <Row className="gy-4">
                    <Col lg={6}>
                      {renderCarousel(IsCounterNonAddon, baseActiveIndex, setBaseActiveIndex, setBaseOfferName)}
                    </Col>
                    <Col lg={6}>
                      <UsageTable resources={nonAddOns} />
                    </Col>
                  </Row>
                </CardBody>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <div><h5>No Records</h5></div>
                </div>
              )}
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader onClick={() => toggleAccordion('3')}>
              <h5 className=' mb-0'>Add On Offer</h5>
            </AccordionHeader>
            <AccordionBody accordionId="3">
              {IsCounterAddon?.length > 0 ? (
                <CardBody className="card-body">
                  <div className="text-start mb-3">
                    <h5>{addOnOfferName}</h5>
                  </div>
                  <Row className="gy-4">
                    <Col lg={6}>
                      {renderCarousel(IsCounterAddon, addOnActiveIndex, setAddOnActiveIndex, setAddOnOfferName)}
                    </Col>
                    <Col lg={6}>
                      <UsageTable resources={addOns} />
                    </Col>
                  </Row>
                </CardBody>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <div><h5>No Records</h5></div>
                </div>
              )}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </Col>
    </Row>
  );
};

export default CustomerGrantBucket;