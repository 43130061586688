import React, { useEffect, useState } from "react";
import { UserManager } from "oidc-client";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchPermissions } from "../permissionsSlice";
import { setWelcomeToast } from "../slices/welcomeToaster/action";

const Callback = ({ userManager, setAuth, handleLogout }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [permissionsFetched, setPermissionsFetched] = useState(false);
  const [hasDashboardPermission, setHasDashboardPermission] = useState(false);
  const [hasCustomerPermission, setHasCustomerPermission] = useState(false);

  const Navigate = useNavigate();
  const permissions = useSelector((state) => state.permissions.permissions);
  const dispatch = useDispatch();
  const roleName = localStorage.getItem("roleName")

  useEffect(() => {
    if (!loading && permissions.length > 0) {
      setPermissionsFetched(true);
      const dashboardPermission = permissions.some(
        (permission) => permission.resourceName === 'dashboard'
      );
      setHasDashboardPermission(dashboardPermission);
      const customerPermission = permissions.some(
        (permission) => permission.resourceName === 'customer'
      )
      setHasCustomerPermission(customerPermission)
    }
  }, [loading, permissions]);

  useEffect(() => {
    if (loading) {
      userManager
        .signinRedirectCallback()
        .then((user) => {
          if (user) {
            setAuth(true);
            dispatch(setWelcomeToast(true));
            localStorage.setItem("permissionss", JSON.stringify(permissions));
            setUserInfo(user);
            if (user?.profile?.['urn:zitadel:iam:user:metadata']) {
              const metadata = user?.profile['urn:zitadel:iam:user:metadata'][0]
              const roleName = atob(metadata?.roleName);
              if (roleName !== "systemUser") {
                const operatorId = atob(metadata?.operatorId);
                const customerId = atob(metadata?.customerId || '');
                localStorage.setItem("operatorId", operatorId);
                localStorage.setItem("customerId", customerId);
              }
              localStorage.setItem("roleName", roleName);
            }
            localStorage.setItem("userId",user?.profile?.sub || '')
            localStorage.setItem("userInfo", JSON.stringify(user));
            localStorage.setItem("token", user.access_token);
            localStorage.setItem("refreshToken", user.refresh_token)
            localStorage.setItem("userName", user.profile.preferred_username);
            dispatch(fetchPermissions());
          } else {
            setAuth(false);
          }
        })
        .catch((error) => {
          setAuth(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [dispatch, loading, userManager, setAuth]);

  useEffect(() => {
    if (permissionsFetched) {
      if (hasDashboardPermission && roleName === "Customer") {
        Navigate('/customer/dashboard')
      }
      else if (hasDashboardPermission) {
        Navigate('/dashboard');
      } else if (hasCustomerPermission) {
        Navigate('/customer');
      } else {
        Navigate('/operator-listing');
      }
    }
  }, [permissionsFetched, hasDashboardPermission, Navigate]);

  useEffect(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
      setLoading(false);
    }
  }, []);

  return null;
};

export default Callback;