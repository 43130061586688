import React, { useEffect, useState, useMemo } from 'react'
import { Button, Card, CardBody, Container } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Row, Col } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from '../../Components/Utils/Loader';
import { fetchAllPartner } from '../../slices/PartnerMangement/thunk'
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setEditSuccessMessage, setSuccessMessage } from '../../slices/toastMessage/action';
import { toast, ToastContainer } from 'react-toastify';
import { PAGE_TITLES } from '../Common/constants';
import { handleExportData } from '../Utils/ExportToCsv';
import { handleExportDataForPartner } from './Constants/HandleExportData';

function Partners() {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchAllPartner(setLoading))
    }, [dispatch])

    const PartnerData = useSelector((state) => state.Partner.partner)
    const permissions = useSelector((state) => state.permissions.permissions);
    const partnerPermission = permissions.find((resource) => resource.resourceName === 'partner');

    const moment = require('moment');
    const formattedOrganizations = PartnerData.map(org => {
        if (org.createdAt) {
            const formattedDate = moment(org.createdAt).format('DD-MMM-YYYY');
            return { ...org, createdAt: formattedDate };
        }
        return org;
    });

    const organizationsWithMaidIds = [];
    PartnerData.forEach(organization => {
        const orgWithMaidId = {
            maidIds: []
        };
        if (organization.contactMedium) {
            organization.contactMedium.forEach(contact => {
                if (contact.characteristic && contact.characteristic.emailAddress) {
                    orgWithMaidId.maidIds.push(contact.characteristic.emailAddress);
                }
            });
        } else {
            orgWithMaidId.maidIds.push("-");
        }
        organizationsWithMaidIds.push(orgWithMaidId);
    });

    const organizationsWithPhoneNum = [];
    PartnerData.forEach(organization => {
        const orgWithPhoneNum = {
            PhoneNums: []
        };
        if (organization.contactMedium) {
            organization.contactMedium.forEach(contact => {
                if (contact.characteristic && contact.characteristic.phoneNumber) {
                    orgWithPhoneNum.PhoneNums.push(contact.characteristic.phoneNumber);
                }
            });
        } else {
            orgWithPhoneNum.PhoneNums.push("-");
        }
        organizationsWithPhoneNum.push(orgWithPhoneNum);
    });

    const navigateToView = async (row) => {
        const extractedId = row?.original?.id?.split('/').pop();
        let path = `/view-partner/${extractedId}`;
        navigate(path);
    };

    const navigateToCreate = () => {
        navigate('/create-partner')
    }

    const navigateToUpdate = async (row) => {
        const extractedId = row?.original?.id?.split('/').pop();
        let path = `/update-partner/${extractedId}`;
        navigate(path);
    };

    const extractedIds = PartnerData.map(item => item?.id?.split('/').pop());
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage)

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Partner Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Partner Updated Successfully`);
            dispatch(setEditSuccessMessage(false))
        }
    }, [showSuccessMessage, dispatch]);

    const columns = useMemo(
        () => [
            {
                accessorFn: (PartnerData, index) => `${extractedIds[index]}`,
                header: 'Partner ID',
                id: 'partnerId',
            },
            {
                accessorKey: 'tradingName',
                header: 'Business Name',
            },
            {
                accessorKey: '@baseType',
                header: 'Type',
            },
            {
                accessorFn: (PartnerData, index) => `${organizationsWithMaidIds[index].maidIds}`,
                header: 'Email',
            },
            {
                accessorFn: (PartnerData, index) => `${organizationsWithPhoneNum[index].PhoneNums}`,
                header: 'phone',
            },
            {
                accessorFn: (PartnerData, index) => `${formattedOrganizations[index].createdAt}`,
                header: 'Created On',
            },
        ],
        [organizationsWithMaidIds],
    );

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.PARTNER_LIST} pageTitle={PAGE_TITLES.PARTNER} />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <ToastContainer position='top-center' />
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {PartnerData ?
                                                <MaterialReactTable
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    muiTableHeadCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    renderTopToolbarCustomActions={({ }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                            <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportDataForPartner(PartnerData) }}>
                                                                <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                                                            </Button>
                                                            {partnerPermission && partnerPermission.permissionList.includes('create') && <Button color="secondary" className="btn-label me-2" onClick={navigateToCreate}>
                                                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Partner
                                                            </Button>}
                                                        </div>
                                                    )}
                                                    muiTableBodyCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    columns={columns}
                                                    data={PartnerData}
                                                    enableColumnOrdering
                                                    enableDensityToggle={false}
                                                    enableColumnDragging
                                                    enableRowActions
                                                    displayColumnDefOptions={{
                                                        'mrt-row-actions': {
                                                            header: '',
                                                            enableHiding: true
                                                        }
                                                    }}
                                                    initialState={{
                                                        columnVisibility: {
                                                            'partnerId': false,
                                                        },
                                                    }}
                                                    renderRowActionMenuItems={({ closeMenu, row }) => [
                                                        partnerPermission && partnerPermission.permissionList.includes('view') ? <MenuItem className='row-options'
                                                            key="edit"
                                                            onClick={() => {
                                                                navigateToView(row);
                                                            }}
                                                        >
                                                            <i className="ri-eye-line me-2"></i>
                                                            View
                                                        </MenuItem> : null,
                                                        partnerPermission && partnerPermission.permissionList.includes('update') ? <MenuItem className='row-options'
                                                            key="edit"
                                                            onClick={() => {
                                                                navigateToUpdate(row);
                                                            }}
                                                        >
                                                            <i className="bx bxs-edit me-2" />
                                                            Edit
                                                        </MenuItem> : null,
                                                    ]}
                                                />
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Partners;