import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Nav, NavItem, NavLink, Row, Col, Button } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { cancelOffer } from './constants/GrantsAndRecurringFunctions';
import { convertOfferFieldsForExporting } from './constants/exportOfferData';
import { MaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllOffers } from '../../../slices/offer/thunk';
import OfferPreview from './OfferPreview';
import { ToastContainer, toast } from 'react-toastify';
import { handleExportData } from '../../Utils/ExportToCsv';
import { chargesColumnData, grantsColumnData, offerColumns, usageRatePlanColumnData } from './constants/ColumnsForOfferTable';
import { extractBaseAndAddOnOfferDates, formatBaseAndAddOnOfferColumns, termValidity } from './constants/checkEmptyFields';
import { tabChange } from '../../Utils/SetDefaultRowData';
import { Loader } from '../../Utils/Loader';
import { setEditSuccessMessage, setSuccessMessage, setDocumentSuccessMessage } from '../../../slices/toastMessage/action';
import { navigateToAddOffer } from './constants/GrantsAndRecurringFunctions';
import { OfferRowOptions } from './OfferRowOptions';
import CommonModal from '../../Utils/CommonModal';
import { PAGE_TITLES } from '../../Common/constants';
import classnames from 'classnames';
import { useColumnSettings } from '../../Utils/useColumnSettings';
import { getView } from '../../../slices/View/thunk';
import { getAllService } from '../../../slices/services/thunk';
import { bulkDelete } from '../../../slices/BulkDelete/thunk';

function OffersListings() {
    const [loading, setLoading] = useState(false);
    const [messageForPopUp, setMessageForPopUp] = useState('');
    const [activeTab, setActiveTab] = useState('0.1');
    const [activeTabForAddOn, setActiveTabForAddOn] = useState('1');
    const [index, setIndex] = useState(null);
    const [offerArray, setOfferArray] = useState([]);
    const [baseRows, setBaseRows] = useState([])
    const [addOnRows, setAddOnRows] = useState([])
    const [modalForBulkDelete, setModalForBulkDelete] = useState(false)
    const [selectedRowsForBulkDelete, setSelectedRowsForBulkDelete] = useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [offerId, setOfferId] = useState('');
    const toggleModal = (id, singleOrMulti) => {
        if (singleOrMulti === "single") {
            setOfferId(id);
            setModal(!modal);
        }
        else {
            setSelectedRowsForBulkDelete(id)
            setModalForBulkDelete(!modalForBulkDelete)
        }
        document.body.style.overflow = 'auto'
    };
    useEffect(() => {
        dispatch(fetchAllOffers(setLoading));
        dispatch(getAllService(setLoading))
        dispatch(getView(setLoading))
    }, [dispatch]);

    const rolename = localStorage.getItem('roleName');
    const offers = useSelector((state) => state.Offer.offer);
    const permissions = useSelector((state) => state.permissions.permissions);
    const offerPermission = permissions.find((resource) => resource.resourceName === 'offer');
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage);
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    const showDocumentSuccessMessage = useSelector((state) => state.SuccessToast.showDocumentSuccessMessage);
    const allEndDate = [];
    const allStartDate = [];
    const globalScope = [];
    const grant = [];
    const charge = [];
    const usage = [];

    const baseOffers = offers.filter(offer => !offer.addOnOffer);
    const addOnOffers = offers.filter(offer => offer.addOnOffer);

    const baseOfferDates = extractBaseAndAddOnOfferDates(baseOffers);
    const addOnOfferDates = extractBaseAndAddOnOfferDates(addOnOffers);

    const baseEndDates = baseOfferDates.endDatesArray;
    const baseStartDates = baseOfferDates.startDatesArray;

    const addOnEndDates = addOnOfferDates.endDatesArray;
    const addOnStartDates = addOnOfferDates.startDatesArray;

    const baseOfferColumnsData = formatBaseAndAddOnOfferColumns(baseOffers);
    const addOnOfferColumnsData = formatBaseAndAddOnOfferColumns(addOnOffers);

    const { globalScope: baseGlobalScope, grant: baseGrant, charge: baseCharge, usage: baseUsage } = baseOfferColumnsData;
    const { globalScope: addOnGlobalScope, grant: addOnGrant, charge: addOnCharge, usage: addOnUsage } = addOnOfferColumnsData;

    const columns = offerColumns(baseGrant, baseCharge, baseUsage, baseGlobalScope, baseStartDates, baseEndDates, rolename);
    const addOnColumns = offerColumns(addOnGrant, addOnCharge, addOnUsage, addOnGlobalScope, addOnStartDates, addOnEndDates, rolename);

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success('Offer Created Successfully');
            dispatch(setSuccessMessage(false));
        }
        if (showEditSuccessMessage) {
            toast.success('Offer Updated Successfully');
            dispatch(setEditSuccessMessage(false));
        }
        if (showDocumentSuccessMessage) {
            toast.success('Import Successful');
            dispatch(setDocumentSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, showDocumentSuccessMessage, dispatch]);

    const grantsColumn = grantsColumnData();
    const chargesColumn = chargesColumnData();
    const usageRatePlanColumn = usageRatePlanColumnData();
    const exportData = convertOfferFieldsForExporting(offers);

    const {
        settingsModal: settingsModal1,
        visibleColumns: visibleColumns1,
        selectedView: selectedView1,
        viewColumns: viewColumns1,
        modalPosition: modalPosition1,
        settingsIconRef: settingsIconRef1,
        toggleSettingsModal: toggleSettingsModal1,
        handleColumnVisibilityChange: handleColumnVisibilityChange1,
        handleSaveSettings: handleSaveSettings1,
        handleViewChange: handleViewChange1,
        SettingsModal: SettingsModal1
    } = useColumnSettings('baseOffer', columns);

    const {
        settingsModal: settingsModal2,
        visibleColumns: visibleColumns2,
        selectedView: selectedView2,
        viewColumns: viewColumns2,
        modalPosition: modalPosition2,
        settingsIconRef: settingsIconRef2,
        toggleSettingsModal: toggleSettingsModal2,
        handleColumnVisibilityChange: handleColumnVisibilityChange2,
        handleSaveSettings: handleSaveSettings2,
        handleViewChange: handleViewChange2,
        SettingsModal: SettingsModal2
    } = useColumnSettings('addOnOffer', addOnColumns);

    const containsNull1 = viewColumns1.some(value => value === undefined);
    const containsNull2 = viewColumns2.some(value => value === undefined);
    const result1 = !containsNull1;
    const result2 = !containsNull2;

    const handleRowSelectionChange = (rows) => {
        if (activeTabForAddOn === "1") {
            setBaseRows(rows);
        } else {
            setAddOnRows(rows);
        }
    };

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.OFFER_LIST} pageTitle={PAGE_TITLES.PRICING} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={12}>
                            <div className="listingjs-table " id="offerList">
                                <Card>
                                    <CardBody>
                                        <div className=' border-bottom'>
                                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0 " role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTabForAddOn === "1" })}
                                                        onClick={() => tabChange("1", activeTabForAddOn, setActiveTabForAddOn)}>
                                                        <i className="fas fa-home"></i>
                                                        Base
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTabForAddOn === "2" })}
                                                        onClick={() => tabChange("2", activeTabForAddOn, setActiveTabForAddOn)}>
                                                        <i className="far fa-user"></i>
                                                        Add-On
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </div>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {(offers && activeTabForAddOn) &&
                                                <MaterialReactTable
                                                    columns={activeTabForAddOn === "1" ? result1 ? columns.filter((column) => column.accessorKey ? viewColumns1.includes(column.accessorKey) : viewColumns1.includes(column.header)) : columns.filter((column) => viewColumns1.includes(column.accessorKey)) : result2 ? addOnColumns.filter((column) => column.accessorKey ? viewColumns2.includes(column.accessorKey) : viewColumns2.includes(column.header)) : addOnColumns.filter((column) => viewColumns2.includes(column.accessorKey))}
                                                    data={activeTabForAddOn === "1" ? baseOffers : addOnOffers}

                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    muiTableHeadCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    muiTableBodyCellProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    muiTableDetailPanelProps={{
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }}
                                                    enableDensityToggle={false}
                                                    positionToolbarAlertBanner='none'
                                                    muiSelectCheckboxProps={{
                                                        color: 'warning'
                                                    }}
                                                    muiSelectAllCheckboxProps={{
                                                        color: 'warning'
                                                    }}
                                                    renderTopToolbarCustomActions={({ table }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
                                                            <div className="d-flex flex-column flex-md-row">
                                                                <Button color='secondary' className='btn-label me-2 mb-2 mb-md-0' onClick={() => navigate('/import-data')} ><i className='ri-download-line label-icon align-middle fs-16 me-2 ' ></i>Import Data</Button>
                                                                <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(exportData) }}> <i className="ri-upload-line label-icon align-middle fs-16 me-2 "></i> Export Data</Button>
                                                                {offerPermission && offerPermission.permissionList.includes('create') ?
                                                                    <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => navigateToAddOffer(navigate)}><i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Offer</Button> : null}
                                                                {table.getSelectedRowModel().rows.length ? <Button color='main-color' className='btn me-2' onClick={() => {
                                                                    const selectedRows = table.getSelectedRowModel().rows;
                                                                    const names = selectedRows.map(row => row.original.offerCode);
                                                                    let uniqueOffers = Array.from(new Set(names))
                                                                    setOfferArray(uniqueOffers);
                                                                    let offerString = uniqueOffers.join(', ')
                                                                    setMessageForPopUp(`Are you sure you want to delete the selected items?`)
                                                                    toggleModal(selectedRows, "multi")
                                                                }}>Delete</Button> : null}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div color="secondary" className="btn-icon me-2" ref={activeTabForAddOn === "1" ? settingsIconRef1 : settingsIconRef2} onClick={activeTabForAddOn === "1" ? toggleSettingsModal1 : toggleSettingsModal2}>
                                                                    <i className="ri-settings-2-line fs-4"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableRowActions
                                                    displayColumnDefOptions={{
                                                        'mrt-row-actions': {
                                                            header: '',
                                                            enableHiding: true
                                                        }
                                                    }}
                                                    initialState={{
                                                        columnVisibility: {
                                                            'mrt-row-expand': false,
                                                        },
                                                        columnOrder: [
                                                            'mrt-row-select',
                                                            'mrt-row-actions'
                                                        ]
                                                    }}
                                                    enableRowSelection
                                                    onRowSelectionChange={handleRowSelectionChange}
                                                    state={activeTabForAddOn === "1" ? { rowSelection: baseRows } : { rowSelection: addOnRows }}
                                                    selectAllMode='all'
                                                    renderRowActionMenuItems={({ closeMenu, row }) => (
                                                        <OfferRowOptions navigate={navigate} setMessageForPopUp={setMessageForPopUp} toggleModal={toggleModal} row={row} closeMenu={closeMenu} setActiveTab={setActiveTab} setIndex={setIndex} setLoading={setLoading} offerPermission={offerPermission} index={index} dispatch={dispatch} />
                                                    )}
                                                    renderDetailPanel={({ row }) => (
                                                        <OfferPreview row={row} tabChange={tabChange} setActiveTab={setActiveTab} allStartDate={allStartDate} allEndDate={allEndDate} termValidity={termValidity} activeTab={activeTab} grantsColumn={grantsColumn} chargesColumn={chargesColumn} usageRatePlanColumn={usageRatePlanColumn} />
                                                    )}
                                                />}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {activeTabForAddOn === "1" ? SettingsModal1() : SettingsModal2()}
            <div>
                <CommonModal open={modalForBulkDelete ? modalForBulkDelete : modal} toggle={() => { if (modal) { toggleModal(offerId, "single") } else { toggleModal(selectedRowsForBulkDelete, "multi") } }} buttonText={"Yes, Delete It"} messageForPopUp={messageForPopUp} modalAction={() => {
                    if (modal) {
                        cancelOffer(dispatch, offerId.original.offerCode, setLoading);
                        toggleModal(offerId, "single");
                    }
                    else {
                        let jsonObj = { ids: offerArray, type: "Offer" }
                        dispatch(bulkDelete(jsonObj, setLoading))
                        toggleModal(selectedRowsForBulkDelete, "multi")
                    }
                }} />
            </div>
        </React.Fragment>
    );
}

export default OffersListings;