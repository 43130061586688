import { countryOptionss } from "../../../Components/OperatorManagement/Constant/Options";
import { STATES_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchStatesSuccess, fetchStatesFailure } from "./reducer";

export const fetchStates = (countryID, isEdit, selectedCountry, optionState,isOperator,setStatedata,setStateOptions) => async (dispatch) => {
    try {
        if (optionState !== []) {
            optionState = []
        }
        if (countryID === "") {
            return;
        }
        else if (isEdit && selectedCountry === "") {
            return;
        }
        else if (selectedCountry === "") {
            return
        }
        else {
            await determineBaseUrlAndPerformApiCall(`${STATES_API}`, 'POST', { country: `${selectedCountry?.label}` }).then((response)=>{
                
                if(isOperator === "isOperator"){
                    let stateOptions = countryOptionss(response?.data?.states)
                    setStateOptions(stateOptions)
                    const extraObject = {
                        name: "state",
                        value: "Operator",
                        options: stateOptions
                    };
                    setStatedata(extraObject)
                }
                dispatch(fetchStatesSuccess(response));
            })
            
        }
    } catch (error) { dispatch(fetchStatesFailure(error.message)) }
}