import { downloadDocApiCall, viewDocApiCall } from "./constants/HandleChangeFunctions"
import { MenuItem } from '@mui/material';

export const InvoiceRowOption = ({partnerOrCustomer,dispatch,setLoading,row,invoiceListData,closeMenu,setProfileData}) => {
    return(
        [
            <MenuItem key={`${row.index}.1`} onClick={() => { partnerOrCustomer = "Invoice", viewDocApiCall(dispatch, setLoading, row.original.id, invoiceListData[row.index].invoice_number,setProfileData), closeMenu() }}><i className="ri-eye-line me-2"></i>View</MenuItem>,
            <MenuItem key={`${row.index}.2`} onClick={() => { downloadDocApiCall(dispatch, setLoading, row.original.id, invoiceListData[row.index].invoice_number), closeMenu() }}><i className="ri-download-line me-2" /> Download</MenuItem>
        ]
    )
}