import { FieldArray } from "formik";
import { CardHeader, Button, CardBody, Table } from "reactstrap";
import { TablesWithoutBorders } from "../../../pages/Tables/BasicTables/BasicTablesCode";
import SelectField from "../../Utils/SelectField";
import InputField from "../../Utils/InputField";
import { Link } from "react-router-dom";
import { removeRowsFromGrantsAndCharges } from "./constants/checkEmptyFields";
import { grantsAndChargesHandle, handleGrantsAndChargesResourceValueAndUsageFields, handleInfiniteForGrantsAndCharges } from "./constants/GrantsAndRecurringFunctions";

export default function ChargesOneTime({
    touched,
    errors,isEdit,
    values, chargeInfinite, chargeFloor, chargeCeiling,
    handleChange, displayCeilingAndFloorForOptions, setChargeCeiling, setChargeFloor,
    infiniteOptions, chargeResourceOptions, setChargeInfinite, resourceData, displayInfiniteForOptions,
    setTouched, setFieldTouched, setFieldValue,
    isEmptyorInvalidFieldForGrantsAndCharges
}) {
    return (
        <FieldArray name="charges">
            {({ push, remove }) => (
                <>
                    <CardHeader className="d-flex justify-content-center align-items-center">
                        <h4 className="card-title mb-0 flex-grow-1 me-2">New Charges</h4>
                        {!isEdit && <Button color="secondary" className="btn-label" onClick={() => {
                            const lastIndex = values.charges.length - 1;
                            if (lastIndex === -1 || !isEmptyorInvalidFieldForGrantsAndCharges(lastIndex, values.charges)) {
                                push({ resourceType: '', recurringFrequency: { value: '0', label: "false" }, resourceValue: "0", ceiling: '', floor: '', prorate: false, type: "1" });
                                setTouched({
                                    ...touched,
                                    charges: {
                                        ...touched.charges,
                                        [lastIndex]: {
                                            resourceType: false,
                                        },
                                    },
                                });
                            }
                        }}   > <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Charges </Button>}
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            <div className="table-responsize">
                                <div className="scrollable-view">
                                    <Table className=" table-borderless align-middle mb-0 ">
                                        <thead>
                                            <tr>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Resource Type<span className="text-danger"> *</span></th>
                                                <th scope="col">Resource Value</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Infinite</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Type of Resource</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Ceiling</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer":""}>Floor</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {values.charges.length === 0 ? (<tr className="default-height"><td colSpan={7} className=" text-center">No Charges</td></tr>) :
                                                (values.charges.map((val, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="field-length">
                                                                    <SelectField
                                                                        className={(
                                                                            touched.charges &&
                                                                            touched.charges[index] &&
                                                                            !values.charges[index].resourceType
                                                                        ) || false ? 'error-input' : '' }
                                                                        name={`charges[${index}].resourceType`} isDisabled={isEdit ? true : false}
                                                                        value={values.charges[index].resourceType}
                                                                        handleChange={grantsAndChargesHandle(`charges[${index}].resourceType`,values.charges[index],displayInfiniteForOptions, displayCeilingAndFloorForOptions, setFieldValue, setFieldTouched, index, resourceData, setChargeInfinite, setChargeCeiling, setChargeFloor)}
                                                                        options={chargeResourceOptions} />
                                                                </div>
                                                                {errors.charges &&
                                                                    errors.charges[index] &&
                                                                    touched.charges &&
                                                                    touched.charges[index] &&
                                                                    touched.charges[index].resourceType ? (
                                                                    <p className="text-danger">
                                                                        {errors.charges[index].resourceType}
                                                                    </p>
                                                                ) : null}
                                                            </td>
                                                            <td>
                                                                <InputField
                                                                    name={`charges[${index}].resourceValue`}
                                                                    className={'form-control field-length3'}
                                                                    placeholder="Enter Amount"
                                                                    type="number"
                                                                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                                                                    value={values.charges[index].resourceValue}
                                                                    invalid={
                                                                        errors.charges &&
                                                                            errors.charges[index] &&
                                                                            touched.charges &&
                                                                            touched.charges[index] &&
                                                                            touched.charges[index].resourceValue && 
                                                                            errors.charges[index].resourceValue ? true : false
                                                                    }
                                                                />
                                                            </td>
                                                            {chargeInfinite[index] ?
                                                                <td>
                                                                    <div className="field-length">
                                                                        <SelectField
                                                                            name={`charges[${index}].infinite`}
                                                                            value={values.charges[index].infinite} isDisabled={isEdit ? true : false}
                                                                            handleChange={handleInfiniteForGrantsAndCharges(`charges[${index}].infinite`,values.charges[index],setFieldValue)}
                                                                            options={infiniteOptions} />
                                                                    </div>
                                                                </td>
                                                                : <td />}
                                                            <td>
                                                                <InputField className={`field-length2 ${isEdit ? "label-disable-color-edit-offer":""}`} value={"Currency"} disabled={true} />
                                                            </td>
                                                            {chargeCeiling[index] || chargeCeiling[index] === 0 ?
                                                                <td>
                                                                    <InputField className={`field-length3 ${isEdit ? "label-disable-color-edit-offer":""}`} value={values.charges[index].ceiling} disabled={true} />
                                                                </td>
                                                                : <td />
                                                            }
                                                            {chargeFloor[index] || chargeFloor[index] === 0 ?
                                                                <td>
                                                                    <InputField className={`field-length3 ${isEdit ? "label-disable-color-edit-offer":""}`} value={values.charges[index].floor} disabled={true} />
                                                                </td>
                                                                : <td />
                                                            }
                                                            {!isEdit && <td>
                                                                <div className="hstack gap-3 fs-15">
                                                                <Link to="#" className="link-danger" onClick={() => {
                                                                        remove(index)
                                                                        removeRowsFromGrantsAndCharges(setChargeInfinite, index, setChargeCeiling, setChargeFloor)
                                                                    }} ><i data-testid="delete" className="ri-delete-bin-5-line"></i></Link>
                                                                </div>
                                                            </td>}
                                                        </tr>
                                                    )
                                                }))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <div className="d-none code-view">
                            <pre className="language-markup default-height">
                                <code>
                                    <TablesWithoutBorders />
                                </code>
                            </pre>
                        </div>
                    </CardBody>
                </>
            )}
        </FieldArray>
    )
}