import { Box } from '@mui/material';
import { getCellStyle } from '../../../Utils/DecideColor';
import { RatePlanOrAttributesColumn } from '../../../Utils/RatePlanColumn';

export const offerColumns = (grant, charge, usage, globalScope, allStartDate, allEndDate, rolename) => {
    const columns = [
        {
            accessorKey: 'offerName',
            header: 'Offer Name',
        },
        {
            accessorKey: 'offerCode',
            header: 'Code',
        },
        {
            accessorFn: (offer, index) => `${grant[index]} ${charge[index]} ${usage[index]} `,
            header: 'Rate Plan',
            Cell: ({ cell }) => {
                const attributes = cell.getValue();
                const [grant, charge, usage] = attributes?.split(' ');
                return (<RatePlanOrAttributesColumn grant={grant} charge={charge} usage={usage} offerOrPolicy={false} />);
            },
            size: 200
        },
        {
            accessorFn: (offer, index) => `${globalScope[index]}`,
            header: 'Global Scope',
        },
        {
            accessorFn: (offer, index) => `${allStartDate[index]}`,
            header: 'Offer Starts On',
        },
        {
            accessorFn: (offer, index) => `${allEndDate[index]}`,
            header: 'Offer Ends On',
        },
    ];

    if (rolename === 'telcoAdmin') {
        columns.push({
            accessorKey: 'policySpecification',
            header: 'PolicySpecification',
        });
    }

    return columns;
};

export const grantsColumnData = () => {
    const grantsColumn = [
        {
            accessorKey: 'resourceName',
            header: 'Resource Name'
        },
        {
            accessorFn: (row) => `${row.recurringFrequency === 1 ? "Recurring" : "One Time"}`,
            header: 'Type'
        },
        {
            accessorKey: 'amount',
            header: 'Amount',
            size: 200,
        },
        {
            accessorFn: (row) => `${row.prorate === false ? "False" : "True"}`,
            header: 'Prorate'
        },
    ];
    return grantsColumn;
};

export const chargesColumnData = () => {
    const chargesColumn = [
        {
            accessorKey: 'resourceName',
            header: 'Resource Name'
        },
        {
            accessorFn: (row) => `${row.recurringFrequency === 1 ? "Recurring" : "One Time"}`,
            header: 'Type'
        },
        {
            accessorKey: 'amount',
            header: 'Price',
            size: 200,
        },
        {
            accessorFn: (row) => `${row.prorate === false ? "False" : "True"}`,
            header: 'Prorate'
        },
    ];
    return chargesColumn;
};

export const usageRatePlanColumnData = () => {
    const usageRatePlanColumn = [
        {
            accessorKey: 'eventType',
            header: 'Event Type'
        },
        {
            accessorKey: 'resourceName',
            header: 'Resource Name'
        },
        {
            accessorKey: 'uomName',
            header: 'UOM'
        },
        {
            accessorKey: 'priority',
            header: 'Priority',
            size: 200,
        },
        {
            accessorFn: (row, index) => `${row.rateTier[index]?.perUnitRate}`,
            header: 'Per Unit Rate'
        },
    ];
    return usageRatePlanColumn;
};
