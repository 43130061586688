import { Button } from "reactstrap";
import { navigateToCreateCustomer } from "./constants/ListingCustomerFunctions";
import { handleExportData } from "../Utils/ExportToCsv";

export const CustomerTableCustomTableButtons = ({toggleModal, setMessageForPopUp, setCustomerArray, table, exportData, customerPermission, navigate }) => {
    return (
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
             <div className="d-flex flex-column flex-md-row">
                <Button
                    color="secondary"
                    className="btn-label me-2 mb-2 mb-md-0"
                    onClick={() => {
                        handleExportData(exportData);
                    }}
                >
                    <i className="ri-upload-line label-icon align-middle fs-16 me-2 "></i>
                    Export Data
                </Button>
            {customerPermission && customerPermission.permissionList.includes('create') &&
                    <Button
                        color="secondary"
                        className="btn-label me-2 mb-2 mb-md-0"
                        onClick={() => navigateToCreateCustomer(navigate)}
                    >
                        <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>
                        New Customer
                    </Button>
            }
            {table.getSelectedRowModel().rows.length ? <Button className="btn me-2" color='main-color' onClick={() => {
                const selectedRows = table.getSelectedRowModel().rows;
                const names = selectedRows.map(row => row.original.CustomerId);
                let uniqueCustomers = Array.from(new Set(names))
                setCustomerArray(uniqueCustomers);
                let customerString = uniqueCustomers.join(', ')
                setMessageForPopUp(`Are you sure you want to delete the selected items?`)
                toggleModal(selectedRows, "multi")
            }}>Close</Button> : null}
            </div>
        </div>
    )
}