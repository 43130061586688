import * as Yup from 'yup';

export const getValidationSchema = (isCurrency) => {
  return Yup.object().shape({
    isCurrency: Yup.mixed().required('Kindly Enter the resource type'),
    name: Yup.string().required('Kindly Enter the resource name'),
    resourceId: isCurrency === true ? Yup.string().required('Kindly Enter the currency number'):Yup.string().required('Kindly Enter the numeric code'),
    currencyCode: isCurrency === true ? Yup.mixed().required('Kindly Enter the currency Code') : Yup.mixed(),
    ceiling: Yup.string().required('Kindly Enter the ceiling'),
    floor: Yup.string().required('Kindly Enter the floor'),
    uom:isCurrency === false ? Yup.mixed().required('Kindly Enter the uom'):Yup.mixed(),
  });
};
