import React from 'react';
import { Link } from 'react-router-dom';
import InputField from '../../Utils/InputField';
import SelectField from '../../Utils/SelectField';
import { Button } from 'reactstrap';
import { handleChangeForImsi } from '../../Customers/constants/HandleChangeFunctions';

const UsageRatePlanRow = ({
    index,
    val,
    isEdit,
    values,
    errors,
    touched,
    handleChange,
    handleGrantsAndChargesResourceValueAndUsageFields,
    handleGrantsAndChargesRecurringFrequencyAndUsageOptions,
    resourceNameOptions,
    uomOptions,
    pricingModelOptions,
    togglePricingModel,
    remove,
    setFieldValue,
    setFieldTouched,
}) => (
    <>
        <tr key={index}>
            <td>
                <InputField
                    name={`usageRatePlan[${index}].usageId`}
                    className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                    placeholder="Enter ID"
                    type="text"
                    disabled={isEdit}
                    handleChange={handleChangeForImsi(setFieldValue,`usageRatePlan[${index}].usageId`)}
                    value={values.usageRatePlan[index].usageId}
                    invalid={
                        errors.usageRatePlan &&
                            errors.usageRatePlan[index] &&
                            touched.usageRatePlan &&
                            touched.usageRatePlan[index] &&
                            touched.usageRatePlan[index].usageId &&
                            !values.usageRatePlan[index].usageId
                            ? true
                            : false
                    }
                />
                {errors.usageRatePlan &&
                    errors.usageRatePlan[index] &&
                    touched.usageRatePlan &&
                    touched.usageRatePlan[index] &&
                    touched.usageRatePlan[index].usageId ? (
                    <p className="text-danger">
                        {errors.usageRatePlan[index].usageId}
                    </p>
                ) : null}
            </td>
            <td>
                <InputField
                    name={`usageRatePlan[${index}].eventType`}
                    className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                    placeholder="Enter Event Type"
                    type="text"
                    disabled={isEdit}
                    handleChange={handleGrantsAndChargesResourceValueAndUsageFields(handleChange)}
                    value={values.usageRatePlan[index].eventType}
                    invalid={
                        errors.usageRatePlan &&
                            errors.usageRatePlan[index] &&
                            touched.usageRatePlan &&
                            touched.usageRatePlan[index] &&
                            touched.usageRatePlan[index].eventType &&
                            !values.usageRatePlan[index].eventType
                            ? true
                            : false
                    }
                />
                {errors.usageRatePlan &&
                    errors.usageRatePlan[index] &&
                    touched.usageRatePlan &&
                    touched.usageRatePlan[index] &&
                    touched.usageRatePlan[index].eventType ? (
                    <p className="text-danger">
                        {errors.usageRatePlan[index].eventType}
                    </p>
                ) : null}
            </td>
            <td>
                <div className="field-length">
                    <SelectField
                        className={(
                            touched.usageRatePlan &&
                            touched.usageRatePlan[index] &&
                            !values.usageRatePlan[index].resourceName
                        ) ||
                            false
                            ? 'error-input'
                            : ''
                        } isDisabled={isEdit}
                        name={`usageRatePlan[${index}].resourceName`}
                        value={values.usageRatePlan[index].resourceName}
                        handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(values.usageRatePlan[index].resourceName, `usageRatePlan[${index}].resourceName`, setFieldValue, setFieldTouched)}
                        options={resourceNameOptions}
                    />
                </div>
                {errors.usageRatePlan &&
                    errors.usageRatePlan[index] &&
                    touched.usageRatePlan &&
                    touched.usageRatePlan[index] &&
                    touched.usageRatePlan[index].resourceName ? (
                    <p className="text-danger">
                        {errors.usageRatePlan[index].resourceName}
                    </p>
                ) : null}
            </td>
            <td>
                <SelectField
                    name={`usageRatePlan[${index}].unitOfMeasurement`}
                    className={(
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        !values.usageRatePlan[index].unitOfMeasurement
                    ) ||
                        false
                        ? 'error-input'
                        : ''
                    }
                    isDisabled={isEdit}
                    options={uomOptions}
                    handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(values.usageRatePlan[index].unitOfMeasurement, `usageRatePlan[${index}].unitOfMeasurement`, setFieldValue, setFieldTouched)} value={values.usageRatePlan[index].unitOfMeasurement}
                    invalid={
                        errors.usageRatePlan &&
                            errors.usageRatePlan[index] &&
                            touched.usageRatePlan &&
                            touched.usageRatePlan[index] &&
                            touched.usageRatePlan[index].unitOfMeasurement &&
                            !values.usageRatePlan[index].unitOfMeasurement
                            ? true
                            : false
                    }
                />
                {errors.usageRatePlan &&
                    errors.usageRatePlan[index] &&
                    touched.usageRatePlan &&
                    touched.usageRatePlan[index] &&
                    touched.usageRatePlan[index].unitOfMeasurement ? (
                    <p className="text-danger">
                        {errors.usageRatePlan[index].unitOfMeasurement}
                    </p>
                ) : null}
            </td>
            <td>
                <InputField
                    name={`usageRatePlan[${index}].priority`}
                    className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                    placeholder="Enter Priority"
                    type="number"
                    disabled={isEdit}
                    handleChange={handleChangeForImsi(setFieldValue,`usageRatePlan[${index}].priority`)}
                    value={values.usageRatePlan[index].priority}
                    invalid={
                        errors.usageRatePlan &&
                            errors.usageRatePlan[index] &&
                            touched.usageRatePlan &&
                            touched.usageRatePlan[index] &&
                            touched.usageRatePlan[index].priority &&
                            !values.usageRatePlan[index].priority
                            ? true
                            : false
                    }
                />
                {errors.usageRatePlan &&
                    errors.usageRatePlan[index] &&
                    touched.usageRatePlan &&
                    touched.usageRatePlan[index] &&
                    touched.usageRatePlan[index].priority ? (
                    <p className="text-danger">
                        {errors.usageRatePlan[index].priority}
                    </p>
                ) : null}
            </td>
            <td>
                <InputField
                    name={`usageRatePlan[${index}].flatRate`}
                    className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                    placeholder="Enter Flat Rate"
                    type="number"
                    disabled={isEdit}
                    handleChange={handleChangeForImsi(setFieldValue,`usageRatePlan[${index}].flatRate`)}
                    value={values.usageRatePlan[index].flatRate}
                    invalid={
                        errors.usageRatePlan &&
                            errors.usageRatePlan[index] &&
                            touched.usageRatePlan &&
                            touched.usageRatePlan[index] &&
                            touched.usageRatePlan[index].flatRate &&
                            !values.usageRatePlan[index].flatRate
                            ? true
                            : false
                    }
                />
                {errors.usageRatePlan &&
                    errors.usageRatePlan[index] &&
                    touched.usageRatePlan &&
                    touched.usageRatePlan[index] &&
                    touched.usageRatePlan[index].flatRate ? (
                    <p className="text-danger">
                        {errors.usageRatePlan[index].flatRate}
                    </p>
                ) : null}
            </td>
            <td>
                <SelectField
                    name={`usageRatePlan[${index}].pricingModel`}
                    className={!isEdit && ((
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        !values.usageRatePlan[index].pricingModel
                    ) ||
                        false
                        ? 'error-input'
                        : '')
                    }
                    isDisabled={isEdit}
                    options={pricingModelOptions}
                    handleChange={handleGrantsAndChargesRecurringFrequencyAndUsageOptions(values.usageRatePlan[index].pricingModel, `usageRatePlan[${index}].pricingModel`, setFieldValue, setFieldTouched)}
                    value={values.usageRatePlan[index].pricingModel}
                    invalid={
                        errors.usageRatePlan &&
                            errors.usageRatePlan[index] &&
                            touched.usageRatePlan &&
                            touched.usageRatePlan[index] &&
                            touched.usageRatePlan[index].pricingModel &&
                            !values.usageRatePlan[index].pricingModel
                            ? true
                            : false
                    }
                />
                {errors.usageRatePlan &&
                    errors.usageRatePlan[index] &&
                    touched.usageRatePlan &&
                    touched.usageRatePlan[index] &&
                    touched.usageRatePlan[index].pricingModel ? (
                    <p className="text-danger">
                        {errors.usageRatePlan[index].pricingModel}
                    </p>
                ) : null} 
                
            </td>
            {val?.pricingModel?.value !== 'volume' ? (
                <td>
                    <InputField
                        name={`usageRatePlan[${index}].perUnitRate`}
                        className={`form-control ${isEdit ? "label-disable-color-edit-offer" : ""}`}
                        placeholder="Enter Per Unit Rate"
                        type="number"
                        disabled={isEdit}
                        handleChange={handleChangeForImsi(setFieldValue,`usageRatePlan[${index}].perUnitRate`)}
                        value={values.usageRatePlan[index].perUnitRate}
                        invalid={
                            errors.usageRatePlan &&
                                errors.usageRatePlan[index] &&
                                touched.usageRatePlan &&
                                touched.usageRatePlan[index] &&
                                touched.usageRatePlan[index].perUnitRate &&
                                !values.usageRatePlan[index].perUnitRate
                                ? true
                                : false
                        }
                    />
                    {errors.usageRatePlan &&
                        errors.usageRatePlan[index] &&
                        touched.usageRatePlan &&
                        touched.usageRatePlan[index] &&
                        touched.usageRatePlan[index].perUnitRate ? (
                        <p className="text-danger">
                            {errors.usageRatePlan[index].perUnitRate}
                        </p>
                    ) : null}
                </td>
            ) : (
                <td>
                    <Button disabled={isEdit} color="primary" onClick={() => togglePricingModel(index)}>
                        View
                    </Button>
                </td>
            )}
            {!isEdit && (
                <td>
                    <div className="hstack gap-3 fs-15">
                        <Link to="#" className="link-danger" onClick={() => remove(index)}>
                            <i data-testid="delete" className="ri-delete-bin-5-line"></i>
                        </Link>
                    </div>
                </td>
            )}
        </tr>
    </>
);
export default UsageRatePlanRow;