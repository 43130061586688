import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Row } from "reactstrap";
import BreadCrumb from "../../Common/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { Loader } from "../../Utils/Loader";
import { attributeColoumns } from "./constants/AttributeColumns";
import { useNavigate } from "react-router-dom";
import { getAllAttribute } from "../../../slices/services/addservice/provisionalattribute/thunk";
import { useDispatch, useSelector } from "react-redux";
import { setEditSuccessMessage, setSuccessMessage } from "../../../slices/toastMessage/action";
import CommonModal from "../../Utils/CommonModal";
import { deleteAttribute } from "../../../slices/attribute/deleteattribute/thunk";
import { AttributeRowOptions } from "./AttributeRowOptions";
import { AttributeCustomButtons } from "./AttributeCustomButtons";
import { useColumnSettings } from "../../Utils/useColumnSettings";
import { getView } from "../../../slices/View/thunk";
import { bulkDelete } from "../../../slices/BulkDelete/thunk";

export default function AttributeList() {
    const [loading, setLoading] = useState()
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [modal, setModal] = useState(false);
    const [attributeName, setAttributeName] = useState()
    const [modalForBulkDelete, setModalForBulkDelete] = useState(false)
    const [selectedRowsForBulkDelete, setSelectedRowsForBulkDelete] = useState()
    const [attributeArray, setAttributeArray] = useState([]);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const columns = attributeColoumns()

    const toggleModal = (id, singleOrMulti) => {
        if (singleOrMulti === "single") {
            setAttributeName(id)
            setModal(!modal);
        }
        else {
            setSelectedRowsForBulkDelete(id)
            setModalForBulkDelete(!modalForBulkDelete)
        }
        document.body.style.overflow = 'auto'
    };
    const {
        settingsModal,
        visibleColumns,
        selectedView,
        viewColumns,
        modalPosition,
        settingsIconRef,
        toggleSettingsModal,
        handleColumnVisibilityChange,
        handleSaveSettings,
        handleViewChange,
        SettingsModal
    } = useColumnSettings('attribute', columns);

    useEffect(() => {
        dispatch(getAllAttribute(setLoading));
        dispatch(getView(setLoading));
    }, [dispatch]);
    const attributes = useSelector((state) => state.Provisional.provisional);
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Attribute Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Attribute Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch])

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title="Attributes" pageTitle="Attributes" />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Card>
                            <CardBody>
                                <Row className="mb-3 mt-1 table-responsive">
                                    {loading && (<Loader />)}
                                    {attributes ?
                                        <MaterialReactTable
                                            icons={{ DragHandleIcon: () => <i className="ri-drag-move-fill" />, }}
                                            muiTableHeadCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            renderTopToolbarCustomActions={({ table }) => (
                                                <AttributeCustomButtons setMessageForPopUp={setMessageForPopUp} setAttributeArray={setAttributeArray} toggleModal={toggleModal} table={table} settingsIconRef={settingsIconRef} toggleSettingsModal={toggleSettingsModal} navigate={navigate} attributes={attributes} />
                                            )}
                                            positionToolbarAlertBanner='none'
                                            muiSelectCheckboxProps={{
                                                color: 'warning'
                                            }}
                                            muiSelectAllCheckboxProps={{
                                                color: 'warning'
                                            }}
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: '',
                                                    enableHiding: true
                                                }
                                            }}
                                            initialState={{
                                                columnOrder: [
                                                    'mrt-row-select',
                                                    'mrt-row-actions',
                                                    'Name',
                                                    'Type',
                                                    'Value Type'
                                                ]
                                            }}
                                            enableRowSelection
                                            selectAllMode="all"
                                            columns={columns.filter((column) => viewColumns.includes(column.accessorKey))}
                                            data={attributes}
                                            enableRowActions
                                            enableColumnOrdering
                                            enableColumnDragging
                                            enableDensityToggle={false}
                                            renderRowActionMenuItems={({ closeMenu, row }) => <AttributeRowOptions navigate={navigate} row={row} setMessageForPopUp={setMessageForPopUp} toggleModal={() => toggleModal(row, "single")} closeMenu={closeMenu} />}
                                        /> : null}
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
            {SettingsModal()}
            <div>
                <CommonModal open={modalForBulkDelete ? modalForBulkDelete : modal} toggle={() => { if (modal) { toggleModal(attributeName, "single") } else { toggleModal(selectedRowsForBulkDelete, "multi") } }} buttonText={"Yes, Delete It"} messageForPopUp={messageForPopUp} modalAction={() => {
                    if (modal) {
                        dispatch(deleteAttribute(attributeName.original.name, setLoading))
                        toggleModal(attributeName, "single")
                    }
                    else {
                        let jsonObj = { ids: attributeArray, type: "Attribute" }
                        dispatch(bulkDelete(jsonObj, setLoading))
                        toggleModal(selectedRowsForBulkDelete, "multi")
                    }
                }} />
            </div>
        </React.Fragment>
    )
}