import moment from "moment";

export const createOrderPayload = (orders, customerId, customerName, authorName) => {
    const payloadArray = orders.map(order => {
        let terminationDate;
        let startDate;
        if (order.termValidity !== "Never Ending") {
            if (order.subscriptionEndsOn === "") {
            } else {
                terminationDate = moment(order.subscriptionEndsOn).format('YYYY-MM-DDTHH:mm:ss[Z]');
            }
        } else {
            if (order.subscriptionEndsOn === "") {
                terminationDate = '0001-01-01T00:00:00Z';
            } else {
                terminationDate = moment(order.subscriptionEndsOn).format('YYYY-MM-DDTHH:mm:ss[Z]');
            }
        }
        if (order.subscriptionStartsOn !== "") {
            startDate = moment(order.subscriptionStartsOn).format('YYYY-MM-DDTHH:mm:ss[Z]');
        }
        return {
            id: order.subscriptionID,
            "@type": "ProductOrderItem",
            action: "add",
            productOffering: {
                id: order.offerCode,
                "@type": "ProductOfferingRef"
            },
            product: {
                id: "100",
                "@type": "Product",
                startDate: startDate,
                terminationDate: terminationDate,
            },
            deviceId: order.selectedDevice,
        };
    });

    const note = [{
        author: authorName,
        date: moment().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        "@type": "Note"
    }];

    const relatedPartyArray = [{
        role: "Customer",
        partyOrPartyRole: {
            id: customerId,
            "@type": "PartyRoleRef"
        },
        "@type": "RelatedPartyRefOrPartyRoleRef"
    }];

    return {
        productOrderItem: payloadArray,
        relatedParty: relatedPartyArray,
        note: note,
        "@type": "ProductOrder"
    };
};
