import { Card, CardBody, CardHeader, InputGroup, InputGroupText, Col, Row } from "reactstrap";
import InputField from "../../Utils/InputField";
import SelectField from "../../Utils/SelectField";
import { handlePolicyForOffer, handleServiceForPolicy } from "./constants/checkEmptyFields";

export default function PolicyForOffer({
    values, errors, touched,
    serviceData, isEdit, 
    policiesOptions,globalState,
    setServiceCode, setSelectedService,
    handleChange,
    setFieldValue
}) {
    return (
        <Card>
            <CardHeader>
                <h4 className={`card-title mb-0 flex-grow-1 ${isEdit ? 'title-disable-color-edit-offer' : ''}`}>Policy Specification</h4>
            </CardHeader>
            <CardBody>
                <Row>
                <Col xxl={4} md={6}>
                    <SelectField
                        className={(errors.policyName && touched.policyName) ? 'error-input' : 'mb-3'}
                        placeholder={"Select a Policy"}
                        name={`policyName`}
                        labelName={"policyName"}
                        value={values.policyName} isDisabled={(isEdit || globalState) ? true : false}
                        isClearable
                        // value = {initialValues.policyName ? serviceOptions?.filter((policyName) => {
                        //     policyName.label === initialValues.policyName.label
                        // }) : values.policyName}
                        handleChange={handlePolicyForOffer(values, setFieldValue)}
                        options={policiesOptions} />
                        
                </Col>
               
                </Row>
                
            </CardBody>
        </Card>
    )
}