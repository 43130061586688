import { Box } from '@mui/material';
import { getCellStyle } from '../../../Utils/DecideColor';
import { RatePlanOrAttributesColumn } from '../../../Utils/RatePlanColumn';

export const useColumns = (
  allStartDate,
  allEndDate,
  allModifyDate,
  isBundle,
  isProvisioning,
  isUsageAttribute
) => {
  return [
    {
      accessorFn: (row) => `${row.name}`,
      header: 'Name',
      size: 150,
    },
    {
      accessorFn: (row) => `${row.serviceCode}`,
      header: 'Service Code',
      size: 150,
    },
    {
      accessorFn: (service, i) => `${isProvisioning[i]} ${isUsageAttribute[i]}`,
      header: 'Attributes',
      size: 80,
      Cell: ({ cell }) => {
        const attributes = cell.getValue();
        const [provisional, usage] = attributes?.split(' ');
        return (
          <RatePlanOrAttributesColumn provisioning={provisional} serviceUsage={usage} />
        );
      },
    },
    {
      accessorFn: (service, i) => `${isBundle[i]}`,
      header: 'Bundled',
      size: 150,
    },
    {
      accessorFn: (service, i) => `${allStartDate[i]}-${allEndDate[i]}`.trim(),
      header: 'Validity',
      size: 150,
    },
    {
      accessorFn: (service, i) => `${allModifyDate[i]}`,
      header: 'Last Modified',
      size: 150,
    },
  ];
};