import * as currencyLibrary from 'currency-library';

export const typevalue = {
    name: "isCurrency",
    value: "Resource",
    options: [
        { value: true, label: 'Monetary' },
        { value: false, label: 'Non-Monetary' },
    ]
};

export const uomOptionsData = (uomData) => {
    let uomArray = []
    uomArray = uomData.map(uom => ({
        value: uom.name,
        label: uom.name
    }));
    return uomArray
}

const allCurrencies = currencyLibrary.getAll();

const optionCurrencyCode = allCurrencies.map((currency) => ({
    value: currency.isoCode,
    label: currency.isoCode,
}));

export { allCurrencies, optionCurrencyCode };

export const resourceOptions = [
    typevalue,
    {
        name: "currencyCode",
        value: "Resource",
        options: optionCurrencyCode
    }
]

export const resOptionsAddition = (uomOptions) => {
    let resArray = []
    if (uomOptions.length) {
        resArray = resourceOptions.push({
            name: "uom",
            value: "Resource",
            options: uomOptions
        });
    }
    return resArray
}