import { Button } from "reactstrap"
import { handleExportData } from "../../Utils/ExportToCsv"
import { navigateToAddAttribute } from "./constants/AttributeJsonObj"

export const AttributeCustomButtons = ({ table, navigate, attributes, settingsIconRef, toggleSettingsModal, toggleModal, setAttributeArray, setMessageForPopUp }) => {
    return (
        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
            <div className="d-flex flex-column flex-md-row">
                <Button color="primary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => { handleExportData(attributes) }}>
                    <i className="ri-upload-line label-icon align-middle fs-16 me-2 " ></i> Export Data
                </Button>
                <Button color="primary" className="btn-label me-2 mb-2 mb-md-0" onClick={() => navigateToAddAttribute(navigate)}>
                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Attribute
                </Button>
                {table.getSelectedRowModel().rows.length ? <Button color='main-color' className="btn me-2" onClick={() => {
                    const selectedRows = table.getSelectedRowModel().rows;
                    const names = selectedRows.map(row => row.original.name);
                    let uniqueAttributes = Array.from(new Set(names))
                    setAttributeArray(uniqueAttributes);
                    let attributeString = uniqueAttributes.join(', ')
                    setMessageForPopUp(`Are you sure you want to delete the selected items?`)
                    toggleModal(selectedRows, "multi")
                }}>Delete</Button> : null}
            </div>
            <div className="d-flex align-items-center">
                <div color="secondary" className="btn-icon me-2" ref={settingsIconRef} onClick={toggleSettingsModal}>
                    <i className="ri-settings-2-line fs-4"></i>
                </div>
            </div>
        </div>
    )
}