import React, { useEffect, useState } from "react";
import { Card, CardBody, Container, Row, Col, Button } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { useDispatch, useSelector } from "react-redux";
import { getAllStation } from "../../slices/stations/thunk";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { staionsColumnData } from "./constants/ColumnsForStationTable";
import { Loader } from "../Utils/Loader";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { toggleAccordion } from "../Customers/constants/constantFunctions";
import Preview from "./Preview";
import { setSuccessMessage } from "../../slices/toastMessage/action";
import { ToastContainer, toast } from "react-toastify";
import { setDefault, storeRow } from "../Utils/SetDefaultRowData";
import { PAGE_TITLES } from "../Common/constants";

function StationListings() {
  const [open, setOpen] = useState('1');
  const [index, setIndex] = useState(null);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage);
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const StationName = [];
  const StationAddress = [];
  const evse = [];
  const connector = [];
  const toggle = toggleAccordion(open, setOpen);

  useEffect(() => {
    dispatch(getAllStation(setLoading));
  }, [dispatch]);

  const stationData = useSelector((state) => state.Stations.station);

  useEffect(() => {
    const filteredData = stationData?.filter(row => {
      const globalFilterLower = globalFilter?.toLowerCase().trim();
      if (!globalFilterLower) return true;
      const searchTerms = globalFilterLower.split(' ');
      return searchTerms.every(term =>
        (row?.stationDetails?.stationID && row?.stationDetails?.stationID.toLowerCase().includes(term)) ||
        (row?.stationDetails?.name && row?.stationDetails?.name.toLowerCase().includes(term)) ||
        (row?.stationDetails?.address && row?.stationDetails?.address.toLowerCase().includes(term)) ||
        (row?.stationDetails?.status && row?.stationDetails?.status.toLowerCase() === term) ||
        (row?.stationDetails?.adminStatus && row?.stationDetails?.adminStatus.toLowerCase() === term)
      );
    });
    setData(filteredData);
  }, [globalFilter, stationData]);

  useEffect(() => {
    if (Array.isArray(stationData)) {
      setStations(stationData);
    }
  }, [stationData]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const stationDetailsArray = stations.map(station => station.stationDetails);

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(stationDetailsArray);
    download(csvConfig)(csv);
  };

  let columns = staionsColumnData(evse, connector, StationName, StationAddress);

  stations.forEach((station, index) => {
    StationName[index] = station?.stationDetails.name || '-';
    StationAddress[index] = station?.stationDetails.address || '-';
    if ("evse" in station?.stationDetails) {
      let evseCount = 0;
      let connectorCount = 0;
      station.stationDetails.evse.forEach((evseDetails) => {
        evseCount += evseDetails.evseid;
        connectorCount += evseDetails.connectors.length;
      });
      evse[index] = evseCount;
      connector[index] = connectorCount;
    } else {
      evse[index] = 0;
      connector[index] = 0;
    }
  });

  const navigateToStation = async (id) => {
    let path = `/station/${id.original.stationDetails.stationID}`;
    navigate(path, { state: { stationDetails: id.original.stationDetails } });
  };

  useEffect(() => {
    if (showSuccessMessage) {
      toast.success(`Station Updated Successfully`);
      dispatch(setSuccessMessage(false));
    }
  }, [showSuccessMessage, dispatch]);

  return (
    <React.Fragment>
      <div id="csms" className="page-content">
        <Container fluid>
          <BreadCrumb title={PAGE_TITLES.STATION_LIST} pageTitle={PAGE_TITLES.STATION} />
          <ToastContainer position="top-center" />
          <Row>
            <Col lg={12}>
              <div className="listingjs-table" id="customerList">
                <Card>
                  <CardBody>
                    <Row className="mb-3 mt-1 table-responsive">
                      {loading && (<Loader />)}
                      {stations ? (
                        <MaterialReactTable
                          icons={{
                            DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                          }}
                          muiTableHeadCellProps={{
                            sx: {
                              fontFamily: "Inter,sans-serif"
                            }
                          }}
                          renderTopToolbarCustomActions={({ }) => (
                            <div className="d-flex flex-column flex-md-row align-items-md-center">
                              <Button
                                color="secondary"
                                className="btn-label me-2 mb-2 mb-md-0"
                                onClick={handleExportData}
                              >
                                <i className="ri-upload-line label-icon align-middle fs-16 me-2"></i>{" "}
                                Export Data
                              </Button>
                            </div>
                          )}
                          muiTableDetailPanelProps={{
                            sx: {
                              fontFamily: "Inter,sans-serif"
                            }
                          }}
                          muiTableBodyCellProps={{
                            sx: {
                              fontFamily: "Inter,sans-serif"
                            }
                          }}
                          columns={columns}
                          data={data}
                          enableColumnOrdering
                          enableColumnDragging
                          enableRowActions
                          enableDensityToggle={false}
                          initialState={{
                            columnVisibility: {
                              'mrt-row-expand': false,
                              'stationDetails.stationID':false,
                            }
                          }}
                          displayColumnDefOptions={{
                            'mrt-row-actions': {
                              header: '',
                              enableHiding: true
                            }
                          }}
                          renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem className='row-options'
                              key="edit"
                              onClick={() => {
                                navigateToStation(row);
                              }}
                            >
                              <i className="bx bxs-edit me-2" />
                              Update
                            </MenuItem>,
                            <MenuItem key="preview" onClick={() => { row.toggleExpanded(); closeMenu(), storeRow(row, setIndex, index) }}
                            > <i className="ri-survey-line me-2" />
                              Preview</MenuItem>
                          ]}
                          manualFiltering
                          onGlobalFilterChange={setGlobalFilter}
                          state={{ globalFilter }}
                          renderDetailPanel={({ row }) => (
                            <Preview
                              row={row}
                              activeTab={activeTab}
                              tabChange={tabChange}
                            />
                          )}
                        />
                      ) : null}
                    </Row>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default StationListings;