import axios from "axios";
import { SessionTimeOut } from "../../Components/Utils/SessionTimeOut";
import ReactDOM from 'react-dom/client';
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

const getBaseUrl = (module) => {
    if (module.includes("/stations") || module.includes("/getReservation") || module.includes("/reserve") || module.includes("/updateReservation")) {
        return `${window.env.REACT_APP_BASE_CSMS_URL}`
    }
    if (module.includes("/device") || module.includes("/devices")) {
        return `${window.env.REACT_APP_DEVICE_API}`
    }
    else if (module.includes("/iso") || module.includes("/state") || module.includes("/states")) {
        return `${window.env.REACT_APP_PLACES_API}`
    }
    else if (module.includes("/login")|| module.includes("/password/reset/expiry") || module === ("/password/reset") || module.includes("/password/set")) {
        return `${window.env.REACT_APP_AUTH_API}`
    }
    else if (module.includes("/payment")) {
        return `${window.env.REACT_APP_PAYMENT_API}`
    }
    else if (module.includes("/chat")) {
        return `${window.env.REACT_APP_CHAT_AI}`
    }
    else {
        return `${window.env.REACT_APP_BASE_URL}`
    }
}

const api = axios.create()

api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('token');
        const operatorID = localStorage.getItem('operatorId');
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
            if (config.data instanceof FormData) {
                config.headers["Content-Type"] = "multipart/form-data"
            } else {
                config.headers["Content-Type"] = "application/json"
            }
            config.validateStatus = function (status) {
                return status >= 200 && status <= 504
            }
            if(config.url.includes("/registerCustomer")){
                let taskId = uuidv4().replace(/-/g, '').substring(0, 21);
                config.headers["taskId"] = taskId
            }
            // if (config.url.includes("/reserve") || config.url.includes("/getReservation") || config.url.includes("/updateReservation") || config.url.includes("/stations/metrics/customer") || config.url.includes("/stations")) {
            //     config.headers["OperatorId"] = operatorID
            // }
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    async (response) => {
        if (response.status === 401) {
            localStorage.clear()
            const root = ReactDOM.createRoot(document.getElementById('csms'));
            root.render(<SessionTimeOut />);
        } else if (response.status >= 500) {
            // toast.error("An Error occurred. Please try again later.");
        }
        if (response.config.url.includes("/chat")||response.config.url.includes("/user/password/reset/url") || response.config.url.includes("/user/email")) {
            return {
                ...response.data,
                status: response.status
            };
        } else {
            return response.data;
        }
    },
    async (error) => {
        if (error.response.status === 401) {
            const root = ReactDOM.createRoot(document.getElementById('csms'));
            await localStorage.clear()
            root.render(<SessionTimeOut />);
        }
        else if (error.response && error.response.status >= 500) {
           // toast.error("An Error occurred. Please try again later.");
        }
        return Promise.reject(error)
    }
)

export const determineBaseUrlAndPerformApiCall = async (endpoint, method, data) => {
    api.defaults.baseURL = getBaseUrl(endpoint)
    const response = await api.request({
        method: method,
        url: endpoint,
        data: data
    })
    return response
}

export default api