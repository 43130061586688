import React from "react";
import { Box } from "@mui/material";
import { getCellStyle } from "../../../Utils/DecideColor";

export const ResourcecolumnData = (isCurrency) => {
  return [
    {
      accessorKey: "name",
      header: "Resource Name",
      size: 150,
    },
    {
     accessorKey: "resourceType",
      accessorFn: (resource, index) => `${isCurrency[index]}`,
      header: "Resource Type",
      size: 150,
      Cell: ({ cell }) => (
        <Box
          sx={(theme) => ({ 
            ...getCellStyle(cell.getValue(),"Resource"),
          })}
        >
          {cell.getValue()}
        </Box>
      ),
    },
    {
      accessorKey: "resourceId",
      header: "Code",
      size: 150,
    },
    {
      accessorKey: "ceiling",
      header: "Ceiling",
      size: 150,
    },
    {
      accessorKey: "floor",
      header: "Floor",
      size: 150,
    },
  ];
};
