import { fetchdeletecustomerSuccess, fetchdeletecustomerFailure } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { DELETE_CUSTOMER_API } from "../../constants/constants";
import { fetchCustomerListSuccess } from "../customerlist/reducer";
import { fetchCustomerList } from "../customerlist/thunk";

export const deletecustomer = (id, setLoading,pageSize,pagination) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DELETE_CUSTOMER_API}/${id}`,'DELETE').then((response) => {
        if (!response.error) {
            setLoading(false)
            toast.success(`${response.message}`)
            dispatch(fetchCustomerList(setLoading, pageSize, pagination.pageSize));
            dispatch(fetchdeletecustomerSuccess(response))
        }
        if (response.error) {
            setLoading(false)
            toast.error(`${response.error.message}`)
            dispatch(fetchdeletecustomerFailure(response.error.message))
        }
    }).catch((errorResp) => { })
}