import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { MaterialReactTable } from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MenuItem } from "@mui/material";
import { LabelText } from "../Utils/Input";
import { fetchAllPartner } from "../../slices/PartnerMangement/thunk";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { partnerListForPolicy } from "./Constants/PolicyColumns";
import { addPartnerDataUsingDispatch, createPartnerDataFunction, mergeAndFilterPartners } from "./Constants/ModifyPolicyDataFunctions";
import moment from "moment"

export const PartnerList = ({ values, partnerId, mergedPartners, setMergedPartners, selectedOffers }) => {
    const [rowSelection, setRowSelection] = useState({});
    const PartnerData = useSelector((state) => state.Partner.partner);
    const isEdit = location.pathname.includes("update-policy");
    const policyId = customerIdData();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const PartnerMainId = partnerId;
    const column = partnerListForPolicy();
    const [partnerDataArray, setPartnerDataArray] = useState([]);
    const settlementPartner = useSelector((state) => state.SelectedPartner.settlementPartner);
    const [partnerDeleted, setPartnerDeleted] = useState(false); 
    let commissionRules = values?.commissionRules
    let policyIdVal = values?.policyName
    let priority = values?.priority

    useEffect(() => {
        dispatch(fetchAllPartner(setLoading));
    }, [dispatch]);

    const filteredArray = partnerId;
    useEffect(() => {
        if (!isEdit) {
            createPartnerDataFunction(PartnerData, partnerId, setPartnerDataArray);
        }
    }, [isEdit, PartnerData, partnerId]);

    useEffect(() => {
        if (!partnerDeleted && !isEdit) {
            addPartnerDataUsingDispatch(filteredArray, partnerDataArray, dispatch, settlementPartner, isEdit);
            
        }
        //future use
        // if(!mergedPartners.length && filteredArray?.length > 0 ){
            mergeAndFilterPartners(settlementPartner, setMergedPartners);
        // }
    }, [dispatch, filteredArray, settlementPartner, partnerDataArray, partnerDeleted, isEdit, setMergedPartners]);

    return (
        <div className="mb-3 mt-1 table-responsive">
            <LabelText displayText={"Partner List"} />
            <div className="d-flex justify-content-end mb-3">
                <Button color="secondary" className="btn-label" onClick={() => {
                    if (!isEdit) {
                        navigate('/partner-for-policy',{state : {selectedOffers,values,commissionRules,policyIdVal,priority}});
                    } else {
                        navigate('/update-partner-for-policy', { state: { partnerId, policyId,selectedOffers } });
                    }
                }}>
                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>ADD
                </Button>
            </div>
            <MaterialReactTable
                icons={{
                    DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                }}
                muiTableHeadCellProps={{
                    sx: {
                        fontFamily: "Inter,sans-serif"
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontFamily: "Inter,sans-serif"
                    }
                }}
                enableTopToolbar={false}
                enableBottomToolbar={true}
                enableDensityToggle={false}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '',
                        enableHiding: true
                    },
                }}
                enableRowActions
                getRowId={(row) => row.partnerId}
                onRowSelectionChange={setRowSelection}
                state={{ rowSelection }}
                renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                        className='row-options'
                        key={row.index}
                        onClick={() => {
                            dispatch({ type: 'REMOVE_PARTNER', payload: row.original.extractedId });
                            setPartnerDeleted(true);
                            closeMenu();
                        }}
                    >
                        <i className="ri ri-delete-bin-5-line me-2 text-danger" />
                        Delete
                    </MenuItem>,
                ]}
                columns={column} data={mergedPartners} />
        </div>
    );
};