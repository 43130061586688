import Select from "react-select"
import makeAnimated from 'react-select/animated';
export default function SelectField({
  className,
  value,
  placeholder,
  handleChange,
  handleBlur,
  options,
  defaultValue,
  isMulti,
  isLoading,
  isDisabled,
  isClearable,
  labelName, menuPortalTarget, theme, closeMenuOnSelect
}) {
  const customStyles = {
    control:(styles) => ({ ...styles, borderRadius: '0.5rem' }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : provided.color,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#0078A3",
      color: "white",
    }),
  };
  const animatedComponents = makeAnimated();
  return (
    <Select
    closeMenuOnSelect={closeMenuOnSelect}
      menuPortalTarget={menuPortalTarget}
      isLoading={isLoading}
      isMulti={isMulti}
      aria-labelledby={labelName}
      className={className}
      components={animatedComponents}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      options={options}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      isClearable={isClearable}
      data-testid="valuetype"
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#015E7F",
          primary25: "#015E7F",
          primary50: "#015E7F",
          primary75: "#015E7F",
        },
      })}
    />
  )
}