import { Box } from "@mui/material";

export const attributeColoumns = () => {
  const columns = [
    {
      accessorKey: 'name',
      header: 'Name',
      size: 150,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 150,
      Cell: ({ cell }) => (
        <Box>
          {cell.row.original.type[0].toUpperCase() + cell.row.original.type.slice(1)}
        </Box>
      )
    },
    {
      accessorKey: 'valueType',
      header: 'Value Type',
      size: 150,
      Cell: ({ cell }) => (
        <Box>
          {cell.row.original.valueType[0].toUpperCase() + cell.row.original.valueType.slice(1)}
        </Box>
      )
    },
  ];

  return columns;
};