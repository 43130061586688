import React, { useEffect, useState } from "react"
import { CardBody, Col, Container, Row, Card, Button } from "reactstrap"
import { MaterialReactTable } from "material-react-table"
import { useDispatch, useSelector } from "react-redux"
import { MenuItem } from '@mui/material';
import { useNavigate } from "react-router-dom"
import { LabelText } from "../Utils/Input"
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { useRef } from "react";
import { offerListForPolicy } from "./Constants/PolicyColumns";
import { addOfferDataUsingDispatch, handleDelete, mergedDataForOfferList } from "./Constants/ModifyPolicyDataFunctions";
import { mergeAndFilterOffers } from "./Constants/ModifyAddPartnerFunctions";
import { columnDataFormatForRatePlan } from "./Constants/ModifyDataFunctions";
export const OfferList = React.memo(({ values, selectedOffers, offerDataArray, mergedArray, setMergedArray }) => {
    const dispatch = useDispatch()
    const isEdit = location.pathname.includes("update-policy")
    let grant = []
    let charge = []
    let usage = []
    let grantsCount = []
    let chargeCount = []
    let usageCount = []

    const policyId = customerIdData()
    const navigate = useNavigate()
    const column = offerListForPolicy(grant, charge, usage, grantsCount, chargeCount, usageCount)
    let orderData = []
    orderData = useSelector((state) => state.SelectedOffer.offersselect);
    const settlementOffers = useSelector((state) => state.SelectedOffer.settlementOffer)
    const [offerDeleted, setOfferDeleted] = useState(false);
    columnDataFormatForRatePlan(settlementOffers, grant, charge, usage, grantsCount, chargeCount, usageCount)
    useEffect(() => {
        if (!isEdit && !offerDeleted) {
            addOfferDataUsingDispatch(selectedOffers, settlementOffers, dispatch);
        }
        //future use
        // if(!mergedArray?.length){
        mergeAndFilterOffers(settlementOffers, setMergedArray);
        // }
    }, [isEdit, offerDeleted, selectedOffers, settlementOffers, dispatch, setMergedArray]);

    return (
        <div className="mb-3 mt-1 table-responsive">
            <LabelText displayText={"Offer List"} />
            <div className="d-flex justify-content-end mb-3">
                <Button color="secondary" className="btn-label" onClick={() => {
                    if (!isEdit) {
                        navigate('/offer-for-policy', { state: values })
                    }
                    else {
                        navigate('/update-offer-for-policy', { state: { selectedOffers, policyId } })
                    }
                }}>
                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i>ADD
                </Button>
            </div>
            <MaterialReactTable
                icons={{
                    DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                }}
                muiTableHeadCellProps={{
                    sx: {
                        fontFamily: "Inter,sans-serif"
                    }
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontFamily: "Inter,sans-serif"
                    }
                }}
                enableTopToolbar={false}
                enableDensityToggle={false}
                enableBottomToolbar={true}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '',
                        enableHiding: true
                    },
                }}
                enableRowActions
                renderRowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                        className='row-options'
                        key={row.index}
                        onClick={() => {
                            setOfferDeleted(true);
                            handleDelete(row.original.offerCode, dispatch);
                            closeMenu();
                        }}
                    >
                        <i className="ri ri-delete-bin-5-line me-2 text-danger" />
                        Delete
                    </MenuItem>,
                ]}
                columns={column} data={mergedArray} />
        </div>
    );
});