import { countryOptionss } from "../../../Components/OperatorManagement/Constant/Options";
import { CITIES_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchCitiesSuccess, fetchCitiesFailure } from "./reducer";

export const fetchCities = (countryID, stateID, selectedCountry, selectedState, optionState,isOperator,setCityData) => async (dispatch) => {
    try {
        if (countryID === "" && stateID === "") {
            return;
        }
        else if (stateID === "") {
            return;
        }
        else if (optionState === [] && selectedCountry === '') {
            return;
        }
        else if (selectedState === '' && selectedCountry === '') {
            return
        }
        else if (selectedState === '' && selectedCountry !== '') {
            return
        }
        else {
            await determineBaseUrlAndPerformApiCall(
                `${CITIES_API}`, 'POST',
                {
                    country: `${selectedCountry?.label}`,
                    state: `${selectedState?.label}`
                }
            ).then((response)=>{
                if(isOperator === "isOperator"){
                    let cityOptions = countryOptionss(response?.data,true)
                    const extraObject = {
                        name: "city",
                        value: "Operator",
                        options: cityOptions
                    };
                    setCityData(extraObject)
                }
                dispatch(fetchCitiesSuccess(response));
            });
            
        }
    } catch (error) { dispatch(fetchCitiesFailure(error.message)) }
}