import React, { useState } from 'react';
import { FieldArray } from 'formik';
import { Card, CardBody, CardHeader, Table, Button } from 'reactstrap';
import { TablesWithoutBorders } from '../../../pages/Tables/BasicTables/BasicTablesCode';
import InputField from '../../Utils/InputField';
import SelectField from '../../Utils/SelectField';
import { Link } from 'react-router-dom';
import { handleGrantsAndChargesResourceValueAndUsageFields, handleGrantsAndChargesRecurringFrequencyAndUsageOptions, isEmptyOrInvalidFieldForPricingModel } from './constants/GrantsAndRecurringFunctions';
import PricingModelTable from './PricingModelTable';
import { pricingModelOptions } from './constants/initialValues';
import UsageRatePlanRow from './UsageRatePlanRow';
export default function UsageRatePlan({
    values,
    errors,
    isEdit,
    touched,
    setFieldTouched,
    setFieldValue,
    setTouched,
    isEmptyOrInvalidField,
    handleChange,
    resourceNameOptions,
    uomOptions,
}) {
    const [showPricingModel, setShowPricingModel] = useState(Array(values.usageRatePlan.length).fill(false));
    const togglePricingModel = (index) => {
        setShowPricingModel(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            return newState;
        });
    };
    return (
        <FieldArray name="usageRatePlan">
            {({ push, remove }) => (
                <Card>
                    <CardHeader className="align-items-center justify-content-center  d-flex">
                        <h4 className="card-title mb-0 flex-grow-1 me-2">New Usage Rate Plan</h4>
                        {!isEdit && (
                            <Button
                                color="secondary"
                                className="btn-label"
                                onClick={() => {
                                    const lastIndex = values.usageRatePlan.length - 1;
                                    if (lastIndex === -1 || !isEmptyOrInvalidField(lastIndex, values)) {
                                        push({ usageId: "1", eventType: '', resourceName: '', unitOfMeasurement: '', priority: "1", perUnitRate: '', flatRate: '', pricingModel: [], pricingModelNew: [] });
                                        setTouched({
                                            ...touched,
                                            usageRatePlan: {
                                                ...touched.usageRatePlan,
                                                [lastIndex]: {
                                                    usageId: false,
                                                    eventType: false,
                                                    resourceName: false,
                                                    unitOfMeasurement: false,
                                                    priority: false,
                                                    perUnitRate: false,
                                                    flatRate: false,
                                                    pricingModel: false
                                                },
                                            },
                                        });
                                    }
                                }}
                            >
                                <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Usage
                            </Button>
                        )}
                    </CardHeader>
                    <CardBody>
                        <div className="live-preview">
                            <div className="table-responsize">
                                <div className="scrollable-view">
                                    <Table className="table-borderless align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer" : ""}>Usage Id</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer" : ""}>Event Type</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer" : ""}>Resource Name</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer" : ""}>Unit of Measurement</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer" : ""}>Priority</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer" : ""}>Flat Rate</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer" : ""}>Pricing Model</th>
                                                <th scope="col" className={isEdit ? "label-disable-color-edit-offer" : ""}>Per Unit Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {values?.usageRatePlan?.length === 0 ? (
                                                <tr className="default-height">
                                                    <td colSpan={8} className="text-center">No Usage Rate Plans</td>
                                                </tr>
                                            ) : null}
                                            {values?.usageRatePlan.map((val, index) => (
                                                <>
                                                    <UsageRatePlanRow
                                                        key={index}
                                                        index={index}
                                                        val={val}
                                                        isEdit={isEdit}
                                                        values={values}
                                                        errors={errors}
                                                        touched={touched}
                                                        handleChange={handleChange}
                                                        handleGrantsAndChargesResourceValueAndUsageFields={handleGrantsAndChargesResourceValueAndUsageFields}
                                                        handleGrantsAndChargesRecurringFrequencyAndUsageOptions={handleGrantsAndChargesRecurringFrequencyAndUsageOptions}
                                                        resourceNameOptions={resourceNameOptions}
                                                        uomOptions={uomOptions}
                                                        pricingModelOptions={pricingModelOptions}
                                                        togglePricingModel={togglePricingModel}
                                                        remove={remove}
                                                        setFieldValue={setFieldValue}
                                                        setFieldTouched={setFieldTouched}
                                                    />
                                                    <tr>
                                                        <td colSpan={9}>
                                                            {(values?.usageRatePlan[index]?.pricingModel?.value === 'volume' && showPricingModel[index])
 && (
                                                                <PricingModelTable
                                                                    values={values}
                                                                    errors={errors}
                                                                    isEdit={isEdit}
                                                                    touched={touched}
                                                                    setFieldTouched={setFieldTouched}
                                                                    setFieldValue={setFieldValue}
                                                                    setTouched={setTouched}
                                                                    handleChange={handleChange}
                                                                    index={index}
                                                                    togglePricingModel={togglePricingModel}
                                                                    setShowPricingModel={setShowPricingModel}
                                                                    onClose={() => setShowPricingModel(false)}
                                                                />
                                                            )}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            )}
        </FieldArray>
    );
}