import { MaterialReactTable } from "material-react-table";
import { Card, CardBody, Row } from "reactstrap";
import { CustomerListRowOptions } from "./CustomerListRowOptions";
import { CustomerPreview } from "./CustomerPreview";
import Simulate from "./Simulate";
import React, { useEffect, useState } from "react";
import CommonModal from "../Utils/CommonModal";
import { deletecustomer } from "../../slices/customer/deletecustomer/thunk";

export const TableForTelco = ({
    getTable,
    searchColumns,
    searchData,
    orderPermission,
    customerPermission,
    navigate,
    dispatch,
    setRenderSimulate,
    setLoading,
    simulateOrPreview,
    index,
    setIndex,
    setActiveTab,
    fetchCustomerDetails,
    stationPermission,
    reservationPermission,
    getCustomerData,
    storeRow,
    setDefault,
    renderSimulate,
    pagination,
    customerData,
    activeTab,
    tabChange,
    setOpen,
    toggle,
    noCurrencyArray,
    currencyArray,
    fetchCustomerSummaryData,
    reservationDataArray,
    duration,
    subscriptionsArray,
    convertNumberOfDaysToMonthYear,
    formatDate,
    getDayDiff,
    current,
    errorMessage,
    errorIcon,
    setGetTable,
    toggleModal,
    setMessageForPopUp,
    setCustomerId,
    messageForPopUp,
    modal,
    customerId,
    TotalCustomers
}) => {
    function transformData(response) {
        return response?.Customers?.map(customer => ({
            CustomerId: customer.CustomerId,
            Name: customer.Name,
            Email: customer.Email,
            Mobile: customer.Mobile,
            Address: customer.Address,
            msisdn: customer.AttributeList?.find(attr => attr.Name?.toLowerCase() === "msisdn")?.Value || "",
            imsi: customer.AttributeList?.find(attr => attr.Name?.toLowerCase() === "imsi")?.Value || ""
        }));
    }
    const transformedData = transformData(TotalCustomers);
    return (
        <>
            <CardBody>
                {!getTable && (!transformedData || transformedData.length === 0) ? (
                    <div className="border rounded static-height d-flex align-items-center justify-content-center">
                        <div className="flex-column" style={{ textAlign: 'center' }}>
                            <i className={`${errorIcon} icon-size flex-column`} />
                            <p className="text-color-telco ">
                                {errorMessage}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="container-fluid px-0">
                        <Card className="disable-margin-card w-100">
                            <CardBody>
                                <Row className="mb-3 mt-1 table-responsive">
                                    <MaterialReactTable
                                        data={!getTable ? (transformedData || []) : (searchData || [])}
                                        columns={searchColumns}
                                        icons={{
                                            DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                        }}
                                        muiTableHeadCellProps={{
                                            sx: {
                                                fontFamily: "Inter,sans-serif",
                                            },
                                        }}
                                        muiTableBodyCellProps={{
                                            sx: {
                                                fontFamily: "Inter,sans-serif",
                                            },
                                        }}
                                        muiTableDetailPanelProps={{
                                            sx: {
                                                fontFamily: "Inter,sans-serif",
                                            },
                                        }}
                                        enableGlobalFilter={false}
                                        enableColumnOrdering={true}
                                        enableColumnDragging={true}
                                        enableDensityToggle={false}
                                        displayColumnDefOptions={{
                                            "mrt-row-actions": {
                                                header: "",
                                                enableHiding: true,
                                            },
                                        }}
                                        enableRowActions={true}                                       
                                        renderRowActionMenuItems={({ closeMenu, row }) => (
                                            <CustomerListRowOptions
                                                orderPermission={orderPermission}
                                                customerPermission={customerPermission}
                                                navigate={navigate}
                                                row={row}
                                                closeMenu={closeMenu}
                                                dispatch={dispatch}
                                                setRenderSimulate={setRenderSimulate}
                                                setLoading={setLoading}
                                                simulateOrPreview={simulateOrPreview}
                                                index={index}
                                                setIndex={setIndex}
                                                setActiveTab={setActiveTab}
                                                fetchCustomerDetails={fetchCustomerDetails}
                                                stationPermission={stationPermission}
                                                reservationPermission={reservationPermission}
                                                getCustomerData={getCustomerData}
                                                storeRow={storeRow}
                                                setDefault={setDefault}
                                                toggleModal={toggleModal}
                                                setMessageForPopUp={setMessageForPopUp}
                                                setCustomerId={setCustomerId}
                                              
                                            />
                                        )}
                                        renderDetailPanel={({ row }) => (
                                            <>
                                                {renderSimulate ? (
                                                    <Simulate row={row} />
                                                ) : (
                                                    <CustomerPreview
                                                        customerData={customerData}
                                                        row={row}
                                                        activeTab={activeTab}
                                                        setActiveTab={setActiveTab}
                                                        tabChange={tabChange}
                                                        open={setOpen}
                                                        setOpen={setOpen}
                                                        toggle={toggle}
                                                        customerAttributeData={customerData?.customerInfo?.attributeList}
                                                        noCurrencyArray={noCurrencyArray}
                                                        currencyArray={currencyArray}
                                                        fetchCustomerSummaryData={fetchCustomerSummaryData}
                                                        reservationDataArray={reservationDataArray}
                                                        duration={duration}
                                                        subscriptionsArray={subscriptionsArray}
                                                        convertNumberOfDaysToMonthYear={convertNumberOfDaysToMonthYear}
                                                        formatDate={formatDate}
                                                        getDayDiff={getDayDiff}
                                                        current={current}
                                                    />
                                                )}
                                            </>
                                        )}
                                    />
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                )}
            </CardBody>
            <CommonModal
                messageForPopUp={messageForPopUp}
                toggle={toggleModal}
                open={modal}
                buttonText={"Yes, Delete It"}
                modalAction={() => {
                    dispatch(deletecustomer(customerId, setLoading, 1, pagination));
                    toggleModal();
                }}
            />
        </>
    );
};
