import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Container, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../Common/BreadCrumb";
import { Loader } from "../Utils/Loader";
import CommonModal from "../Utils/CommonModal";
import { setEditSuccessMessage, setEmailUpdateSuccessMessage, setSuccessMessage } from "../../slices/toastMessage/action";
import { UserCustomButtons } from "./UserCustomButton";
import { UserRowOptions } from "./UserRowOption";
import { extractedDataForUser, userColoumns } from "./Constant/UserManagementColoumn";
import { getAllUser } from "../../slices/UserManagement/thunk";
import { deleteUser } from "../../slices/UserManagement/DeleteUser/thunk";
import { useColumnSettings } from '../Utils/useColumnSettings';
import { getView } from '../../slices/View/thunk';

export default function UserManagementListing() {
    const [loading, setLoading] = useState()
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [modal, setModal] = useState(false);
    const [userName, setUserName] = useState()
    const [globalFilter, setGlobalFilter] = useState('');
    const [columnFilters, setColumnFilters] = useState([]);
    const [data,setData] =useState([])
    const roleName = localStorage.getItem("roleName")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const columns = userColoumns()
    const location = useLocation()
    const operatorId = localStorage.getItem('operatorId');

    const user = useSelector((state) => state?.GetAllUser?.getAllUser)
    const permissions = useSelector((state) => state.permissions.permissions);
    const userPermission = permissions.find((resource) => resource.resourceName === 'userManagement');
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);
    const showEmailUpdateSuccessMessage = useSelector((state) => state.SuccessToast.showEmailUpdateSuccessMessage)

    const updatedData = useMemo(() => {
        const listingData = extractedDataForUser(user);
        return listingData.map(item => ({
            ...item,
            status: item.status === 'active' ? 'Active' : (item.status === 'inactive' ? 'Inactive' : (item.status === 'unverified' ? 'Unverified' : '-'))
        }));
    }, [user]);

    const toggleModal = (id) => {
        setUserName(id)
        setModal(!modal);
        document.body.style.overflow = 'auto'
    };

    const handleCancel = () => {
        setGlobalFilter('');
        setColumnFilters([]);
        setData(user);
    };

    const handleGlobalFilterChange = (filter) => {
        setGlobalFilter(filter);
        if (!filter) {
            handleCancel();
        }
    };
    
    useEffect(() => {
        dispatch(getAllUser(setLoading, operatorId));
        dispatch(getView(setLoading))
    }, [dispatch]);
    
    useEffect(() => {
        const filteredData = updatedData.filter(row => {
            const globalFilterLower = globalFilter.toLowerCase();
            const columnFilterChecks = columnFilters.map(filter => {
                const filterValue = filter?.value?.toLowerCase();
                const cellValue = row[filter.id]?.toString().toLowerCase();
                if (filter.id === 'status') {
                    return cellValue === filterValue;
                }
                return cellValue?.includes(filterValue);
            });
    
            const userFieldMatches = (
                (row?.userId && typeof row?.userId === 'string' && row?.userId.toLowerCase().includes(globalFilterLower)) ||
                (row?.userName && typeof row?.userName === 'string' && row?.userName.toLowerCase().includes(globalFilterLower)) ||
                (row?.fullName && typeof row?.fullName === 'string' && row?.fullName.toLowerCase().includes(globalFilterLower)) ||
                (row?.role && typeof row?.role === 'string' && row?.role.toLowerCase().includes(globalFilterLower)) ||
                (row?.email && typeof row?.email === "string" && row?.email?.toLowerCase().includes(globalFilterLower)) ||
                (row?.phone && typeof row?.phone === "string" && row?.phone?.toLowerCase().includes(globalFilterLower)) ||
                (row?.status && row?.status?.toLowerCase() === globalFilterLower)
            );
    
            const matchesGlobalFilter = userFieldMatches;
            return matchesGlobalFilter && columnFilterChecks.every(Boolean);
        });
    
        setData(filteredData);
    }, [globalFilter, columnFilters, updatedData]);
    
    
    const {
        settingsModal,
        visibleColumns,
        selectedView,
        viewColumns,
        modalPosition,
        settingsIconRef,
        toggleSettingsModal,
        handleColumnVisibilityChange,
        handleSaveSettings,
        handleViewChange,
        SettingsModal
    } = useColumnSettings('user', columns);

    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`An email link for setting the password has been sent.`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`User Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }

    }, [showSuccessMessage, showEditSuccessMessage, dispatch])

    useEffect(() => {
        if (showEmailUpdateSuccessMessage) {
            toast.success(`Verification link has been sent to your updated email`);
            dispatch(setEmailUpdateSuccessMessage(false));
        }
    }, [showEmailUpdateSuccessMessage])
    const containsNull = viewColumns.some(value => value === undefined);
    const result = !containsNull;
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title="Users" pageTitle="User" />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Card>
                            <CardBody>
                                <Row className="mb-3 mt-1 table-responsive">
                                    {loading && (<Loader />)}
                                    {user ?
                                        <MaterialReactTable
                                            icons={{ DragHandleIcon: () => <i className="ri-drag-move-fill" />, }}
                                            muiTableHeadCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            muiTableBodyCellProps={{
                                                sx: {
                                                    fontFamily: "Inter,sans-serif"
                                                }
                                            }}
                                            renderTopToolbarCustomActions={({ }) => (
                                                <UserCustomButtons settingsIconRef={settingsIconRef} toggleSettingsModal={toggleSettingsModal} Policies={user} navigate={navigate} userPermission={userPermission} />
                                            )}
                                            displayColumnDefOptions={{
                                                'mrt-row-actions': {
                                                    header: '',
                                                    enableHiding: true
                                                }
                                            }}
                                            columns={result ? columns.filter((column) => column.accessorKey ? viewColumns.includes(column.accessorKey) : viewColumns.includes(column.header)) : columns.filter((column) => viewColumns.includes(column.accessorKey))}
                                            data={data}
                                            enableRowActions
                                            enableColumnOrdering
                                            enableColumnDragging
                                            onColumnFiltersChange={setColumnFilters}
                                            onGlobalFilterChange={handleGlobalFilterChange}
                                            state={{ globalFilter, columnFilters }}
                                            enableDensityToggle={false}
                                            renderRowActionMenuItems={({ closeMenu, row }) => <UserRowOptions userPermission={userPermission} location={location} dispatch={dispatch} navigate={navigate} row={row} setMessageForPopUp={setMessageForPopUp} toggleModal={toggleModal} closeMenu={closeMenu} setUserName={setUserName} setLoading={setLoading} />}
                                        /> : null}
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>
                </Container>
            </div>
            {SettingsModal()}
            <div>
                <CommonModal open={modal} toggle={toggleModal} buttonText={"Yes, Delete It"} messageForPopUp={messageForPopUp} modalAction={() => {
                    dispatch(deleteUser(userName, setLoading, operatorId))
                    toggleModal()
                }} />
            </div>
        </React.Fragment>
    )
}