import { TabContent, TabPane, Accordion, AccordionBody, AccordionHeader, AccordionItem, Nav, NavItem, NavLink, Row, Col, Card, CardBody } from "reactstrap"

import GrantsOneTime from "./GrantsOneTime";
import GrantsRecurring from "./GrantsRecurring";
import ChargesOneTime from "./ChargesOneTime";
import ChargesRecurring from "./ChargesRecurring";

import classnames from "classnames";
import UsageRatePlan from "./UsageRatePlan";
export default function OfferAccordion({
    loading,globalState,
    setLoading,
    resourceData,setInfinite,setRecurringInfinite,setChargeInfinite,setChargeRecurringInfinite,
    open,displayCeilingAndFloorForOptions,setRecurringFloor,setRecurringCeiling,
    toggle,setCeiling,setFloor,setChargeCeiling,setChargeFloor,setChargeRecurringCeiling,setChargeRecurringFloor,
    activeTab,setActiveTab, setChargesActiveTab,
    tabChange,
    values,isEdit,
    stateCharges,
    setStateCharges,
    uomOptions,
    isEmptyOrInvalidField,
    resourceNameOptions,
    setFieldTouched,
    prorateState,
    setProrateState,
    setFieldValue,
    handleProrateState,
    handleChargesProrateState,
    isEmptyorInvalidFieldForGrantsAndCharges,
    touched,
    chargesActiveTab,
    chargesTabChange,
    errors,
    chargeInfinite,
    chargeFloor,
    chargeCeiling,
    handleChange,
    infiniteOptions,
    chargeResourceOptions,
    setTouched,
    infinite,
    floor,
    ceiling,
    displayInfiniteForOptions,
    grantResourceOptions,
    state,
    setState,
    recurringFrequencyOptions,
    chargeRecurringInfinite,
    chargeRecurringCeiling,
    chargeRecurringFloor,
    recurringInfinite,
    recurringCeiling,
    recurringFloor,
}) {
    return (
        <Accordion open={open} toggle={toggle} className={globalState ? 'global-state-true' : ''}>
            <AccordionItem>
                <AccordionHeader className={globalState ? 'disbled-accordion-button' : ''}   targetId="1" > <h4 className={`card-title mb-0 flex-grow-1 ${isEdit ? 'title-disable-color-edit-offer' : ''}`}>Grants</h4></AccordionHeader>
                <AccordionBody accordionId="1" >
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1",activeTab,setActiveTab);
                                                }}>
                                                <i className="fas fa-home"></i>
                                                One-Time
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2",activeTab,setActiveTab);
                                                }}
                                                type="button">
                                                <i className="far fa-user"></i>
                                                Recurring
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <CardBody>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                <GrantsOneTime
                                                    touched={touched}
                                                    loading={loading}
                                                    displayCeilingAndFloorForOptions={displayCeilingAndFloorForOptions}
                                                    setLoading={setLoading}
                                                    setInfinite={setInfinite}
                                                    errors={errors} isEdit={isEdit}
                                                    values={values}
                                                    infinite={infinite}
                                                    ceiling={ceiling}
                                                    floor={floor}
                                                    setCeiling={setCeiling}
                                                    setFloor={setFloor}
                                                    resourceData={resourceData}
                                                    infiniteOptions={infiniteOptions}
                                                    grantResourceOptions={grantResourceOptions}
                                                    displayInfiniteForOptions={displayInfiniteForOptions}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    setTouched={setTouched}
                                                    handleChange={handleChange}
                                                    isEmptyorInvalidFieldForGrantsAndCharges={isEmptyorInvalidFieldForGrantsAndCharges}
                                                />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <GrantsRecurring
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    state={state} isEdit={isEdit}
                                                    setRecurringFloor={setRecurringFloor}
                                                    setRecurringCeiling={setRecurringCeiling}
                                                    displayCeilingAndFloorForOptions={displayCeilingAndFloorForOptions}
                                                    setRecurringInfinite={setRecurringInfinite}
                                                    prorateState={prorateState}
                                                    setProrateState={setProrateState}
                                                    setState={setState}
                                                    resourceData={resourceData}
                                                    handleProrateState={handleProrateState}
                                                    setTouched={setTouched}
                                                    handleChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    recurringCeiling={recurringCeiling}
                                                    recurringFloor={recurringFloor}
                                                    recurringInfinite={recurringInfinite}
                                                    infiniteOptions={infiniteOptions}
                                                    recurringFrequencyOptions={recurringFrequencyOptions}
                                                    grantResourceOptions={grantResourceOptions}
                                                    displayInfiniteForOptions={displayInfiniteForOptions}
                                                    isEmptyorInvalidFieldForGrantsAndCharges={isEmptyorInvalidFieldForGrantsAndCharges}
                                                />
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </AccordionBody>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeader targetId="2" > <h4 className={`card-title mb-0 flex-grow-1 ${isEdit ? 'title-disable-color-edit-offer' : ''}`}>Charges</h4></AccordionHeader>
                <AccordionBody accordionId="2" >
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: chargesActiveTab === "1" })}
                                                onClick={() => {
                                                    chargesTabChange("1",chargesActiveTab,setChargesActiveTab);
                                                }}>
                                                <i className="fas fa-home"></i>
                                                One-Time
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: chargesActiveTab === "2" })}
                                                onClick={() => {
                                                    chargesTabChange("2",chargesActiveTab,setChargesActiveTab);
                                                }}
                                                type="button">
                                                <i className="far fa-user"></i>
                                                Recurring
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <CardBody>
                                        <TabContent activeTab={chargesActiveTab}>
                                            <TabPane tabId="1">
                                                <ChargesOneTime
                                                    touched={touched}
                                                    errors={errors} isEdit={isEdit}
                                                    values={values}
                                                    setChargeInfinite={setChargeInfinite}
                                                    resourceData={resourceData}
                                                    chargeInfinite={chargeInfinite}
                                                    chargeCeiling={chargeCeiling}
                                                    chargeFloor={chargeFloor}
                                                    infiniteOptions={infiniteOptions}
                                                    chargeResourceOptions={chargeResourceOptions}
                                                    displayInfiniteForOptions={displayInfiniteForOptions}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue}
                                                    setTouched={setTouched}
                                                    handleChange={handleChange}
                                                    setChargeCeiling={setChargeCeiling}
                                                    setChargeFloor={setChargeFloor}
                                                    displayCeilingAndFloorForOptions={displayCeilingAndFloorForOptions}
                                                    isEmptyorInvalidFieldForGrantsAndCharges={isEmptyorInvalidFieldForGrantsAndCharges}
                                                />
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <ChargesRecurring
                                                    values={values}
                                                    errors={errors} isEdit={isEdit}
                                                    touched={touched}
                                                    setChargeRecurringInfinite={setChargeRecurringInfinite}
                                                    resourceData={resourceData}
                                                    stateCharges={stateCharges}
                                                    setStateCharges={setStateCharges}
                                                    setTouched={setTouched}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    handleChange={handleChange}
                                                    handleChargesProrateState = {handleChargesProrateState}
                                                    chargeRecurringCeiling={chargeRecurringCeiling}
                                                    chargeRecurringFloor={chargeRecurringFloor}
                                                    chargeRecurringInfinite={chargeRecurringInfinite}
                                                    infiniteOptions={infiniteOptions}
                                                    setChargeRecurringCeiling={setChargeRecurringCeiling}
                                                    setChargeRecurringFloor={setChargeRecurringFloor}
                                                    recurringFrequencyOptions={recurringFrequencyOptions}
                                                    chargeResourceOptions={chargeResourceOptions}
                                                    displayCeilingAndFloorForOptions={displayCeilingAndFloorForOptions}
                                                    displayInfiniteForOptions={displayInfiniteForOptions}
                                                    isEmptyorInvalidFieldForGrantsAndCharges={isEmptyorInvalidFieldForGrantsAndCharges}
                                                />
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </AccordionBody>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeader targetId="3" > <h4 className={`card-title mb-0 flex-grow-1 ${isEdit ? 'title-disable-color-edit-offer' : ''}`}>Usage</h4></AccordionHeader>
                <AccordionBody accordionId="3" >
                    <Row>
                        <Col lg={12} >
                            <UsageRatePlan
                                values={values} isEdit={isEdit}
                                touched={touched}
                                errors={errors}
                                setTouched={setTouched}
                                setFieldTouched={setFieldTouched}
                                setFieldValue={setFieldValue}
                                isEmptyOrInvalidField={isEmptyOrInvalidField}
                                handleChange={handleChange}
                                resourceNameOptions={resourceNameOptions}
                                uomOptions={uomOptions}
                            />
                        </Col>
                    </Row>
                </AccordionBody>
            </AccordionItem>
        </Accordion>
    )
}
