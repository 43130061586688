import { MaterialReactTable } from "material-react-table"
import { Card } from "reactstrap"
import { attributeColumnData } from "./constants/ListingCustomerFunctions"

export const AttributeTableInView = ({ attributeData }) => {
  const column = attributeColumnData()
  return (
    <Card>
      {attributeData ? <MaterialReactTable
        columns={column}
        data={attributeData}
        muiTableHeadCellProps={{
          sx: {
            fontFamily: "Inter,sans-serif",
          },
        }}
        muiTablePaperProps={{
          elevation: 0,
        }}
        muiTableBodyCellProps={{
          sx: {
            fontFamily: "Inter,sans-serif",
            border: "none",
          },
        }}
        enableBottomToolbar={false}
        enableColumnActions={false}
        enableDensityToggle={false}
        enablePagination={false}
      /> : null}
    </Card>
  )
}