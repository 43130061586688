import { Card, CardBody, Col, Row } from "reactstrap";
import { LabelText } from "../../Utils/Input";
import formSchema from "../../Utils/schema.json";
import { RenderField } from "../../Utils/renderFields";
import { RenderErrorMessage } from "../../Utils/renderErrorMessages";
import { attributeOptions } from "./constants/InitialValues";
import { useEffect } from "react";

export default function AttributeDetails({ setState, values, handleChange, handleBlur, errors, touched, setFieldTouched, setFieldValue, setIsChecked, checked, setMandatoryIsChecked, mandatoryChecked,setMandatoryIsCheckedSelfcare,mandatoryCheckedSelfcare,setFieldError }) {

    useEffect(() => {
        const isListType = values.valueType?.value === "list";
        formSchema.forEach((section) => {
            if (section.title === "Attribute" && section.sections) {
                section.sections.forEach((subSection) => {
                    subSection.fields.forEach((field) => {
                        if (field.name === "minimumValue" || field.name === "maximumValue") {
                            field.required = !isListType;
                        }
                    });
                });
            }
        });
        setState(!isListType);
    }, [values.valueType]);
    return (
        <Card>
            <CardBody className='card-body mb-5'>
                <div className='live-preview'>
                    {formSchema.map((section, sectionIndex) => (
                        <Row className='gy-4 py-2' key={sectionIndex}>
                            {(section.title === "Attribute" && section.sections) &&
                                section.sections.map((subSection, subSectionIndex) => {
                                    let fieldCount = 0;
                                    let conditionMet = false;
                                    return subSection.fields.filter((field) => {
                                        if (field.name === "attributeValue" && (values.valueType?.value !== "list")) {
                                            conditionMet = true;
                                            return false;
                                        }
                                        else if (field.name === "SelfcareAttribute"  && (values.valueType?.value !== "bool")) {
                                            conditionMet = true;
                                            return false;
                                        }
                                        if (field.name === "searchable") {
                                            if (!(values.type?.value === "customer" && (values.valueType?.value === "string"))) {
                                              return false;
                                            }
                                        }
                                        if (field.name === "SelfcareAttribute") {
                                            if (!(values.type?.value === "customer" && (values.valueType?.value === "bool"))) {
                                                return false;
                                            }
                                        }
                                        if (field.name === "Selfcare") {
                                            if (!(values.type?.value === "customer" && (values.valueType?.value === "bool"))) {
                                                return false;
                                            }
                                            else {
                                                return true;
                                            }
                                        }
                                        return true;
                                    }).map((field, fieldIndex) => {
                                        fieldCount++;
                                        const colElement = (
                                            <Col
                                                xxl={field.name === "description" ? 12 : 3}
                                                md={12}
                                                lg={12}
                                                key={`${subSectionIndex}-${fieldIndex}`}
                                                className={field.name === "description" && "mb-4"}
                                            >
                                                <LabelText
                                                    htmlFor={field.name}
                                                    className={field.required ? "text-danger" : ""}
                                                    displayText={field.label}
                                                    important={field.required ? "*" : ""}
                                                />
                                                <RenderField field={field}
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values} mandatoryChecked={mandatoryChecked}
                                                    setMandatoryIsChecked={setMandatoryIsChecked}
                                                    setMandatoryIsCheckedSelfcare={setMandatoryIsCheckedSelfcare}
                                                    mandatoryCheckedSelfcare={mandatoryCheckedSelfcare}
                                                    touched={touched} isChecked={checked}
                                                    errors={errors} setIsChecked={setIsChecked}
                                                    setFieldTouched={setFieldTouched}
                                                    setFieldValue={setFieldValue} options={attributeOptions}
                                                    setFieldError={setFieldError} isAttribute={true} />
                                                <RenderErrorMessage
                                                    field={field}
                                                    touched={touched}
                                                    errors={errors}
                                                />
                                            </Col>
                                        );

                                        const elements = [colElement];

                                        if ((!conditionMet && fieldCount === 7) || (conditionMet && fieldCount === 6) || (conditionMet && fieldCount === 3)) {
                                            elements.push(<Col key={`empty-${subSectionIndex}-${fieldIndex}`} />);
                                        }

                                        return elements;
                                    });
                                })
                            }
                        </Row>
                    ))}

                </div>
            </CardBody>
        </Card>
    )
}