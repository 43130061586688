import { MenuItem } from '@mui/material';
import { deleteDocument, downloadDocApiCall, toggleModal, viewDocApiCall } from './constants/HandleChangeFunctions';

export const UploadDocumentRowOptions = ({ row, dispatch, setLoading, customerId, closeMenu, setFields, setEditingRow, setEditedDocumentName, setEditedDocumentType, setButtonText, setMessageForPopUp, setIsOpen, open, setProfileData }) => {
    return (
        [
            <MenuItem key={`${row.index}.1`} onClick={() => { viewDocApiCall(dispatch, setLoading, customerId, row.original.documentName,setProfileData), closeMenu() }}><i className="ri-survey-line me-2" /> Preview</MenuItem>,
            <MenuItem key={`${row.index}.2`} onClick={() => { setFields(true), closeMenu(), setEditingRow(row.index), setEditedDocumentName(row.original.documentName), setEditedDocumentType({ value: row.original.documentType, label: row.original.documentType }); }}><i className='bx bxs-edit me-2' /> Edit</MenuItem>,
            <MenuItem key={`${row.index}.3`} onClick={() => { downloadDocApiCall(dispatch, setLoading, customerId, row.original.documentName), closeMenu() }}><i className="ri-download-line me-2" /> Download</MenuItem>,
            row.original.status !== "Approved" && <MenuItem key={`${row.index}.4`} onClick={(e) => {
                closeMenu()
                e.stopPropagation()
                toggleModal(setIsOpen, open)
                deleteDocument(customerId, row.original.documentName)
                setButtonText('Yes,Delete It')
                setMessageForPopUp(`Are you sure you want to Delete this document \'${row.original.documentName}\'?`)
            }}><i className="ri-delete-bin-5-line me-2" />
                Delete</MenuItem>
        ]
    )
}